import { useEffect, useRef, useState } from "react";

import Loader from "../../components/loader";
import Pagination from "../../components/pagination";
import { useNavigate } from "react-router-dom";
import SidebarItem from "../../components/sidebarItem";
import { BsCopy, BsSignpost2, BsTrash } from "react-icons/bs";
import { closePopupMenu } from "../../utils/closePopupMenu";
import { HiDotsVertical } from "react-icons/hi";
import moment from "moment";
import toast from "react-hot-toast";
import {
  deleteContract,
  getContracts,
} from "../../controllers/contractControllers";

const ContractsPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(8);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [count, setCount] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);
  useEffect(() => {
    closePopupMenu(dropdownRef, () => {
      setShowOptions(false);
    });
  }, [dropdownRef]);
  useEffect(() => {
    getData();
  }, [page, keyword]);
  const getData = () => {
    const path = `?limit=${limit}&page=${page}&keyword=${keyword}`;
    getContracts(path).then((response) => {
      console.log(response.data.body);
      const body = response.data.body;
      setData(body.rows);
      setCount(body.count);
      setLoading(false);
    });
  };
  return loading ? (
    <Loader />
  ) : (
    <div>
      <div className="flex justify-between items-start">
        <div className="space-y-1">
          <h1 className="text-2xl 2xl:text-xl font-bold">Contracts</h1>
          <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
            Manage contracts below
          </p>
        </div>
        {
          <button
            onClick={() => {
              navigate(`/add-contract`);
            }}
            className="py-2 px-6 font-semibold rounded-lg bg-primary text-white"
          >
            Add Contract
          </button>
        }
      </div>
      <div className="bg-white  dark:bg-darkLight rounded-xl mt-3 ">
        <div className="bg-background dark:bg-black rounded-t-2xl bg-opacity-40 dark:bg-opacity-10 px-6 items-center py-4 flex justify-between">
          <h1 className="font-bold text-lg">Contracts ({data.length})</h1>
          <input
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            className="search-style"
            placeholder="Search here"
          />
        </div>
        <div className="px-6 py-5">
          <table className="">
            <thead>
              <tr>
                <th className="">Created At</th>
                <th className="">Recipient</th>
                <th className="">Password</th>
                <th className="">Status</th>
              </tr>
            </thead>
            <tbody className="mt-2">
              {data.map((item) => {
                return (
                  <tr>
                    <td className="">
                      {moment(item.createdAt).format("yyy, MMM DD")}
                    </td>
                    <td className="">{item.recipient}</td>
                    <td className="">{item.password}</td>

                    <td className="">
                      <button
                        className={`${
                          item.status == "Signed"
                            ? "bg-green-200"
                            : "bg-gray-200"
                        } text-sm px-3 py-1 rounded-lg`}
                      >
                        {item.status}
                      </button>
                    </td>
                    <td className="">
                      <div className="relative">
                        <HiDotsVertical
                          className=" cursor-pointer"
                          onClick={() => {
                            if (showOptions == false) {
                              setSelectedItem(item);
                              setShowOptions(!showOptions);
                            }
                          }}
                        />
                        {showOptions == true &&
                          selectedItem.uuid == item.uuid && (
                            <div ref={dropdownRef} className="popup-style">
                              <SidebarItem
                                isSidebar={false}
                                icon={<BsCopy />}
                                onClick={() => {
                                  const link = `https://dashboard.kwanza.io/influencer-contract/${item.uuid}`;
                                  // Copy the link to clipboard
                                  navigator.clipboard
                                    .writeText(link)
                                    .then(() => {
                                      toast.success(
                                        "Contract link copied to clipboard!"
                                      );
                                      setShowOptions(false);
                                      setSelectedItem(null);
                                    })
                                    .catch((err) => {
                                      console.error("Failed to copy: ", err);
                                    });
                                }}
                                title={"Copy Contract Link"}
                              />
                              <SidebarItem
                                isSidebar={false}
                                icon={<BsTrash />}
                                onClick={() => {
                                  toast.success("Deleted Successfully");
                                  deleteContract(item.uuid).then((res) => {
                                    setShowOptions(false);
                                    setSelectedItem(false);
                                    getData();
                                  });
                                }}
                                title={"Delete Contract"}
                              />
                            </div>
                          )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            limit={limit}
            count={count}
            setPage={setPage}
            page={page}
          />
        </div>
      </div>
    </div>
  );
};

export default ContractsPage;
