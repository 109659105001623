import { useContext, useEffect, useRef, useState } from "react";
import { getPublishers, getUsers } from "../../controllers/userController";
import Spinner from "../../components/spinner";
import Loader from "../../components/loader";
import Pagination from "../../components/pagination";
import { useNavigate, useParams } from "react-router-dom";
import SidebarItem from "../../components/sidebarItem";
import { BsSignpost2, BsTrash } from "react-icons/bs";
import { LiaBuysellads } from "react-icons/lia";
import { closePopupMenu } from "../../utils/closePopupMenu";
import { HiDotsVertical } from "react-icons/hi";
import { ImEarth } from "react-icons/im";
import { GrStatusPlaceholder } from "react-icons/gr";
import Back from "../../components/back";
import { getWebsite } from "../../controllers/websitesController";
import {
  deleteWebsiteCategory,
  getWebsiteCategories,
} from "../../controllers/websiteCategoriesController";
import moment from "moment";
import toast from "react-hot-toast";
import {
  deleteChannel,
  getChannels,
} from "../../controllers/channelController";
import { useGetParams } from "../../utils/getParams";
import {
  deleteTraditionalCampaign,
  getTraditionalCampaigns,
} from "../../controllers/traditionalCampaignController";
import {
  AiOutlineBarChart,
  AiOutlineBars,
  AiOutlineEdit,
} from "react-icons/ai";
import { MdInsertChartOutlined } from "react-icons/md";
import { UserContext } from "../../layouts/dashboardLayout";

const TraditionalCampaigns = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { user } = useContext(UserContext);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(8);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [count, setCount] = useState(0);
  const params = useGetParams();
  const [selectedItem, setSelectedItem] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);
  useEffect(() => {
    closePopupMenu(dropdownRef, () => {
      setShowOptions(false);
    });
  }, [dropdownRef]);
  useEffect(() => {
    getData();
  }, [page, keyword]);
  const getData = () => {
    let path = `${params.uuid}/?limit=${limit}&page=${page}&keyword=${keyword}`;

    getTraditionalCampaigns(path).then((response) => {
      console.log(response);
      const rows = response.data.body.rows;
      const count = response.data.body.count;
      console.log(rows);
      setData(rows);
      setCount(count);
      setLoading(false);
    });
  };
  return loading ? (
    <Loader />
  ) : (
    <div>
      <Back />
      <div className="flex justify-between items-start">
        <div className="space-y-2">
          <h1 className="text-2xl 2xl:text-xl font-bold">Reports</h1>
          <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
            Manage traditional campaigns Below
          </p>
        </div>
        {user.role == "admin" && (
          <button
            onClick={() => {
              navigate(`/add-traditional-campaign/?uuid=${params.uuid}`);
            }}
            className="py-2 px-6 font-semibold rounded-lg bg-primary text-white"
          >
            Add Campaign
          </button>
        )}
      </div>
      <div className="bg-white  dark:bg-darkLight rounded-xl mt-4 ">
        <div className="bg-background dark:bg-black rounded-t-2xl bg-opacity-40 dark:bg-opacity-10 px-6 items-center py-4 flex justify-between">
          <h1 className="font-bold text-lg">Campaigns ({data.length})</h1>
          <input
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            className="search-style"
            placeholder="Search here"
          />
        </div>
        <div className="px-6 py-5">
          <table className="">
            <thead>
              <tr>
                <th className="">Created At</th>
                <th className="">Type</th>
                <th className="">Name</th>
                <th className="">Budget</th>
                <th className="">Activate Time</th>
                <th className="">Expire Time</th>
              </tr>
            </thead>
            <tbody className="mt-2">
              {data.map((item) => {
                return (
                  <tr key={item.uuid}>
                    <td className="">
                      {moment(item.createdAt).format("yyy, MMM DD")}
                    </td>
                    <td className="">{item.type}</td>
                    <td className="">{item.name}</td>
                    <td className="">{`$${item.budget.toLocaleString()}`}</td>
                    <td className="">
                      {moment(item.activateTime).format("yyy, MMM DD")}
                    </td>
                    <td className="">
                      {moment(item.expireTime).format("yyy, MMM DD")}
                    </td>
                    <td className="">{item.media}</td>
                    <td className="">
                      <div className="relative">
                        <HiDotsVertical
                          className=" cursor-pointer"
                          onClick={() => {
                            if (showOptions == false) {
                              setSelectedItem(item);
                              setShowOptions(!showOptions);
                            }
                          }}
                        />
                        {showOptions == true &&
                          selectedItem.uuid == item.uuid && (
                            <div ref={dropdownRef} className="popup-style">
                              {user.role == "agency user" ? (
                                <SidebarItem
                                  isSidebar={false}
                                  icon={<MdInsertChartOutlined />}
                                  path={`/traditional-campaign-stats/?uuid=${item.uuid}`}
                                  title={"Campaign Report"}
                                />
                              ) : (
                                <SidebarItem
                                  isSidebar={false}
                                  icon={<MdInsertChartOutlined />}
                                  path={`/traditional-campaign-report/?uuid=${item.uuid}&name=${item.name}`}
                                  title={"Campaign Report"}
                                />
                              )}
                              {user.role == "admin" && (
                                <div>
                                  <SidebarItem
                                    isSidebar={false}
                                    icon={<AiOutlineEdit />}
                                    path={`/edit-traditional-campaign/?uuid=${item.uuid}&name=${item.name}`}
                                    title={"Edit Campaign"}
                                  />
                                  <SidebarItem
                                    isSidebar={false}
                                    icon={<BsTrash />}
                                    onClick={() => {
                                      toast.success("Deleted Successfully");
                                      deleteTraditionalCampaign(item.uuid).then(
                                        (res) => {
                                          setShowOptions(false);
                                          setSelectedItem(false);
                                          getData();
                                        }
                                      );
                                    }}
                                    title={"Delete Campaign"}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            limit={limit}
            count={count}
            setPage={setPage}
            page={page}
          />
        </div>
      </div>
    </div>
  );
};

export default TraditionalCampaigns;
