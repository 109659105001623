import React from "react";
import PropTypes from "prop-types";

const ProgressBar = ({ value }) => {
  // Ensure value is between 0 and 100
  const safeValue = Math.min(100, Math.max(0, value));

  return (
    <div className="w-24 bg-gray-300 rounded-full h-2 overflow-hidden">
      <div
        className="h-full bg-primary transition-all duration-300"
        style={{ width: `${safeValue}%` }}
      ></div>
    </div>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
};

export default ProgressBar;
