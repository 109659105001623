import { useLocation, useNavigate } from "react-router-dom";

const SidebarComponent = ({ path, icon, label, onClick }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <button
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          navigate(path);
        }
      }}
      className={`group relative w-full flex font-medium items-center cursor-pointer
         hover:bg-white hover:bg-opacity-10 py-2 rounded-lg
         transition-all duration-200 px-4 space-x-3  ${
           pathname == path && "bg-primary dark:bg-primary"
         }`}
    >
      <div className="text-xl"> {icon}</div>
      <p> {label}</p>
    </button>
  );
};

export default SidebarComponent;
