const InfluencerCompanies = () => {
  return (
    <div>
      <div className="w-6/12">
        <div className="flex justify-between items-center border-b border-gray-100">
          <div>
            <h1 className="text-2xl font-bold">Companies</h1>
            <p className="text-muted text-sm mt-1">
              List of companies you can follow
            </p>
          </div>
          <div className="w-4/12">
            <input placeholder="Search here" className="input-style " />
          </div>{" "}
        </div>
        <div className="pb-24">
          {[
            {
              company: "Vodacom",
              description: "Telcom company",
              image:
                "https://solomon.co.tz/wp-content/uploads/2018/12/vodacom.png",
              link: "",
            },
            {
              company: "Stanbic Bank",
              description: "Microfinance Company ",
              image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSf3HlbJCSYFF5JUL37iOlu_BEFKYD_GK5JVQ&s",
              link: "",
            },
          ].map((item) => {
            return (
              <div
                onClick={() => {
                  //   if (selectedCompany == item.company) {
                  //     setSelectedCompany(null);
                  //   } else {
                  //     setSelectedCompany(item.company);
                  //   }
                }}
                className=" mt-4"
              >
                <div className="flex space-x-2 mt-2 justify-between items-center cursor-pointer">
                  <div className="flex space-x-2">
                    <img className="size-12" src={item.image} />
                    <div>
                      <h1 className="text-lgs font-bold">{item.company}</h1>
                      <p className="text-sm text-muted ">{item.description}</p>
                    </div>
                  </div>
                  <button className="text-sm py-2 bg-primary px-3 text-white font-medium font-medium">
                    Follow
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default InfluencerCompanies;
