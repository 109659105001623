import { useEffect, useRef, useState } from "react";
import {
  deleteUser,
  getInfluencers,
  getUsers,
} from "../../controllers/userController";
import Spinner from "../../components/spinner";
import Loader from "../../components/loader";
import Pagination from "../../components/pagination";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import SidebarItem from "../../components/sidebarItem";
import { AiOutlineCopy, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import toast from "react-hot-toast";
import { closePopupMenu } from "../../utils/closePopupMenu";
import { HiDotsVertical } from "react-icons/hi";
import Back from "../../components/back";
import { getInfluencerGroups } from "../../controllers/influencerGroupsController";
import { BsPeople } from "react-icons/bs";
import { useGetParams } from "../../utils/getParams";
import Switch from "../../components/switch";
import {
  addInfluencerGroupMembers,
  deleteInfluencerGroupMembers,
  getInfluencerGroupMembers,
} from "../../controllers/influencerGroupMembersController";

const GroupInfluencers = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [adding, setAdding] = useState(true);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(14);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [count, setCount] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [groupMembers, setGroupMembers] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const params = useGetParams();
  const dropdownRef = useRef(null);

  useEffect(() => {
    closePopupMenu(dropdownRef, () => {
      setShowOptions(false);
    });
  }, [dropdownRef]);
  useEffect(() => {
    getData();
  }, [page, keyword]);
  const getData = () => {
    console.log(limit, page);
    let path = `?limit=${limit}&page=${page}&keyword=${keyword}`;
    getInfluencers(path).then((response) => {
      console.log();
      const rows = response.data.body.rows;
      const count = response.data.body.count;
      console.log(rows);
      setData(rows);
      setCount(count);
      setAdding(false);
      setSelectedItem(null);
      setLoading(false);
    });
  };
  return loading ? (
    <Loader />
  ) : (
    <div>
      <Back />
      <div className="flex justify-between items-start">
        <div className="space-y-1">
          <h1 className="text-2xl 2xl:text-xl font-bold">Influencers</h1>
          <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
            Manage influencers Below
          </p>
        </div>
        <button
          onClick={() => {
            navigator.clipboard
              .writeText(
                `https://dashboard.kwanza.io/shared-influencers-group/${params.uuid}`
              )
              .then((res) => {
                toast.success("Copied successfully");
              });
          }}
          className="py-2 px-6 font-semibold rounded-lg bg-primary text-white"
        >
          Copy Link
        </button>
      </div>
      <div className="bg-white  dark:bg-darkLight rounded-xl mt-4 ">
        <div className="bg-background dark:bg-black rounded-t-2xl bg-opacity-40 dark:bg-opacity-10 px-6 items-center py-4 flex justify-between">
          <h1 className="font-bold text-lg">Influencers ({count})</h1>
          <input
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            className="search-style"
            placeholder="Search here"
          />
        </div>
        <div className="px-6 py-5">
          <table className="">
            <thead>
              <tr>
                <th className="">Created At</th>
                <th className="">Name</th>
              </tr>
            </thead>
            <tbody className="mt-2">
              {data.map((item) => {
                return (
                  <tr key={item.uuid}>
                    <td className="">
                      {moment(item.createdAt).format("yyy,MMM DD")}
                    </td>
                    <td className="">{item.name}</td>
                    <td className="flex items-center space-x-2 justify-center">
                      {adding && selectedItem?.uuid == item.uuid ? (
                        <div className="size-6 border-4 border-t-transparent animate-spin rounded-full border-primary" />
                      ) : (
                        <Switch
                          onClick={() => {
                            setSelectedItem(item);
                            if (
                              item.InfluencerGroupMembers.filter(
                                (item) =>
                                  item.InfluencersGroup?.uuid == params.uuid
                              ).length > 0
                            ) {
                              setAdding(true);
                              toast.success("Removed successfully");
                              const members =
                                item.InfluencerGroupMembers.filter(
                                  (item) =>
                                    item.InfluencersGroup?.uuid == params.uuid
                                );
                              console.log(members[0].uuid);
                              deleteInfluencerGroupMembers(
                                members[0].uuid
                              ).then((res) => {
                                getData();
                              });
                              console.log("deleating");
                            } else {
                              setAdding(true);
                              toast.success("Added ssuccessfully");
                              addInfluencerGroupMembers({
                                user_uuid: item.uuid,
                                group_uuid: params.uuid,
                              }).then((res) => {
                                getData();
                              });
                            }
                          }}
                          isActive={
                            item.InfluencerGroupMembers.filter(
                              (item) =>
                                item.InfluencersGroup?.uuid == params.uuid
                            ).length > 0
                          }
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            limit={limit}
            count={count}
            setPage={setPage}
            page={page}
          />
        </div>
      </div>
    </div>
  );
};

export default GroupInfluencers;
