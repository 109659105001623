import React, { useEffect, useState } from "react";
import TextForm from "../textForm";
import SubmitButton from "../submitButton";
import { getUser } from "../../controllers/userController";
import { useGetParams } from "../../utils/getParams";
import { useParams } from "react-router-dom";
import {
  addInfluencerDetail,
  editInfluencerDetail,
} from "../../controllers/influencerDetailController";
import toast from "react-hot-toast";

const PrimaryDetailsForm = ({ user, getData }) => {
  const [formData, setFormData] = useState({ ...user.InfluencerDetail });

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Primary Details:", formData);
    editInfluencerDetail(user.InfluencerDetail.uuid, formData).then((res) => {
      getData();
      toast.success("Saved successfully");
    });
    // Here you can send `formData` to an API or handle the data accordingly
  };

  return (
    <form onSubmit={handleSubmit} className="w-full bg-white p-8 rounded-lg">
      <h1 className="font-bold text-lg">Primary Information</h1>
      <p className="text-sm text-muted mb-4">Edit influencer details below</p>
      <div className="grid grid-cols-3 gap-4">
        <TextForm
          required={false}
          label={"Profile URL"}
          placeholder={"Paste profile image url"}
          name={"profileUrl"}
          inputType={"text"}
          value={formData.profileUrl}
          onChange={handleInputChange}
        />
        <TextForm
          required={false}
          label={"Instagram Handle"}
          placeholder={"Enter Instagram handle"}
          name={"instagramHandle"}
          inputType={"text"}
          value={formData.instagramHandle}
          onChange={handleInputChange}
        />
        <TextForm
          required={false}
          label={"Instagram Link"}
          placeholder={"Enter Instagram link"}
          name={"instagramLink"}
          inputType={"url"}
          value={formData.instagramLink}
          onChange={handleInputChange}
        />
        <TextForm
          required={false}
          label={"YouTube Handle"}
          placeholder={"Enter YouTube handle"}
          name={"youtubeHandle"}
          inputType={"text"}
          value={formData.youtubeHandle}
          onChange={handleInputChange}
        />
        <TextForm
          required={false}
          label={"YouTube Link"}
          placeholder={"Enter YouTube link"}
          name={"youtubeLink"}
          inputType={"url"}
          value={formData.youtubeLink}
          onChange={handleInputChange}
        />
        <TextForm
          required={false}
          label={"TikTok Handle"}
          placeholder={"Enter TikTok handle"}
          name={"tiktokHandle"}
          inputType={"text"}
          value={formData.tiktokHandle}
          onChange={handleInputChange}
        />
        <TextForm
          required={false}
          label={"TikTok Link"}
          placeholder={"Enter TikTok link"}
          name={"tiktokLink"}
          inputType={"url"}
          value={formData.tiktokLink}
          onChange={handleInputChange}
        />
        <TextForm
          required={false}
          label={"X Handle (formerly Twitter)"}
          placeholder={"Enter X handle"}
          name={"xHandle"}
          inputType={"text"}
          value={formData.xHandle}
          onChange={handleInputChange}
        />
        <TextForm
          required={false}
          label={"X Link (formerly Twitter)"}
          placeholder={"Enter X link"}
          name={"xLink"}
          inputType={"url"}
          value={formData.xLink}
          onChange={handleInputChange}
        />
        <TextForm
          required={false}
          label={"Tag"}
          placeholder={"Enter influencer tag"}
          name={"tag"}
          inputType={"text"}
          value={formData.tag}
          onChange={handleInputChange}
        />
        <TextForm
          required={false}
          label={"Real"}
          placeholder={"Enter real value"}
          name={"real"}
          inputType={"number"}
          value={formData.real}
          onChange={handleInputChange}
        />
        <TextForm
          required={false}
          label={"Country"}
          placeholder={"Enter country"}
          name={"country"}
          inputType={"text"}
          value={formData.country}
          onChange={handleInputChange}
        />
        <TextForm
          required={false}
          label={"Status"}
          placeholder={"Enter status"}
          name={"status"}
          inputType={"text"}
          value={formData.status}
          onChange={handleInputChange}
        />
        <TextForm
          required={false}
          label={"Followers (X)"}
          placeholder={"Enter number of followers on X"}
          name={"followersX"}
          inputType={"number"}
          value={formData.followersX}
          onChange={handleInputChange}
        />
        <TextForm
          required={false}
          label={"Subscribers (YouTube)"}
          placeholder={"Enter number of followers on YouTube"}
          name={"followersYoutube"}
          inputType={"number"}
          value={formData.followersYoutube}
          onChange={handleInputChange}
        />
        <TextForm
          required={false}
          label={"Followers (TikTok)"}
          placeholder={"Enter number of followers on TikTok"}
          name={"followersTiktok"}
          inputType={"number"}
          value={formData.followersTiktok}
          onChange={handleInputChange}
        />
        <TextForm
          required={false}
          label={"Followers (Instagram)"}
          placeholder={"Enter number of followers on Instagram"}
          name={"followersInstagram"}
          inputType={"number"}
          value={formData.followersInstagram}
          onChange={handleInputChange}
        />
        <TextForm
          required={false}
          label={"Saturation Rate"}
          placeholder={"Enter saturation rate"}
          name={"saturationRate"}
          inputType={"number"}
          value={formData.saturationRate}
          onChange={handleInputChange}
        />
        <TextForm
          required={false}
          label={"Post Effectiveness"}
          placeholder={"Enter post effectiveness"}
          name={"postEffectiveness"}
          inputType={"number"}
          value={formData.postEffectiveness}
          onChange={handleInputChange}
        />
      </div>
      <SubmitButton text={"Save changes"} />
    </form>
  );
};

export default PrimaryDetailsForm;
