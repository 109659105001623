import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { sendCode, verifyCode } from "../../controllers/authController";
import { saveTokens } from "../../utils/authStore";
import VerificationInput from "react-verification-input";
import OtpInput from "react-otp-input";
const ConfirmPage = () => {
  const { identifier } = useParams();
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(null);
  const [resend, setResend] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="w-screen h-screen bg-background  flex justify-center items-center text-dark">
      <div className=" h-screen  rounded-xl bg-white  items-center flex">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            let email = null;
            let phone = null;
            if (identifier.includes("@")) {
              email = identifier;
            } else {
              phone = identifier;
            }
            setLoading(true);
            const payload = {
              email,
              phone,
              code: e.target.code.value,
            };
            verifyCode(payload)
              .then((response) => {
                setLoading(false);
                const token = response.data.body.tokens.ACCESS_TOKEN;
                toast.success("Logged in successfully");
                saveTokens(token);
                navigate("/");
                // navigate(`/confirm/${email || phone}`);
              })
              .catch((err) => {
                setLoading(false);
                e.target.code.value = "";
                const message = err.response.data.message;
                toast.error(message);
              });
          }}
          className="w-full md:w-6/12 py-24 flex flex-col items-start px-10 2xl:px-72 md:px-48 justify-center"
        >
          <h1 className="font-bold text-2xl md:text-3xl mb-2">
            Confirm Account
          </h1>
          <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
            Enter confirmation code we sent to{" "}
            <span className="font-bold">{identifier}</span>
          </p>
          <div className="space-y-2 mt-12 w-full">
            <div className="flex flex-col space-y-2 w-full">
              <div className="flex justify-between">
                <label>Code</label>
                <div className="flex space-x-2 items-center">
                  {resend && (
                    <div className="size-3 border-2 rounded-full border-primary animate-spin border-t-transparent"></div>
                  )}
                  <p
                    onClick={() => {
                      let email = null;
                      let phone = null;
                      if (identifier.includes("@")) {
                        email = identifier;
                      } else {
                        phone = identifier;
                      }
                      setResend(true);
                      const payload = {
                        email,
                        phone,
                      };
                      sendCode(payload).then(() => {
                        setResend(false);
                        toast.success("Check your email for confirmation code");
                      });
                    }}
                    className="text-xs font-bold cursor-pointer hover:text-primary  text-muted dark:text-white dark:text-opacity-50"
                  >
                    {resend ? "Sending new Code" : " Resend Code"}
                  </p>
                </div>
              </div>
              <input
                className="input-style"
                type="number"
                required
                placeholder="Enter code here"
                name="code"
              />
              {/* <input
                name="code"
                className="w-full focus:border-primary focus:ring-primary rounded-lg"
                placeholder="*********"
              /> */}
            </div>
          </div>
          {
            <button
              type="submit"
              className="bg-primary py-3 transition-all w-full  font-semibold rounded-lg mt-4 text-white hover:bg-opacity-85  "
            >
              {loading ? "Checking Code..." : "Confirm"}
            </button>
          }
          <p className="text-sm mt-2">
            Go back to{" "}
            <span
              onClick={() => {
                navigate("/login");
              }}
              className="text-primary font-bold cursor-pointer hover:scale-105 transition-all duration-200"
            >
              login
            </span>
          </p>
        </form>
        <div className="w-6/12 hidden md:block  ">
          <div className=" ">
            <img className="" src="/loginimg.png" />
            <h1 className="font-bold text-2xl mt-4 pe-16">
              A Few clicks away from publishing your ads
            </h1>
            <p className="text-muted text-sm pe-16">
              With just a few clicks, you can easily publish your ads and share
              them with your target audience. Our streamlined process ensures
              that creating, managing, and launching your ads is quick and
              hassle-free. Whether you're promoting products, services, or
              events, we make it simple to get your message out to the right
              people in no time. Take the stress out of advertising and focus on
              growing your reach effortlessly!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPage;
