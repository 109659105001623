const { getTokens } = require("../utils/authStore");
const { app } = require("./authController");

export const addInfluencerGroupMembers = async (data) => {
  return await app.post("/influencer-group-members/", data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const getInfluencerGroupMembers = async (uuid) => {
  return await app.get(`/influencer-group-members/group/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const editInfluencerGroupMembers = async (uuid, data) => {
  return await app.patch(`/influencer-group-members/${uuid}`, data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const deleteInfluencerGroupMembers = async (uuid) => {
  return await app.delete(`/influencer-group-members/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
