const { getTokens } = require("../utils/authStore");
const { app } = require("./authController");

export const addInfluencerBrandMention = async (data) => {
  return await app.post("/influencer-brand-mentions/", data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const getInfluencerBrandMentions = async (path) => {
  return await app.get(`/influencer-brand-mentions/${path}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const getInfluencerBrandMention = async (uuid) => {
  return await app.patch(`/influencer-brand-mentions/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const editInfluencerBrandMention = async (uuid, data) => {
  return await app.patch(`/influencer-brand-mentions/${uuid}`, data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const deleteInfluencerBrandMention = async (uuid) => {
  return await app.delete(`/influencer-brand-mentions/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
