import { useEffect, useRef, useState } from "react";
import { deleteUser, getUsers } from "../../controllers/userController";
import Spinner from "../../components/spinner";
import Loader from "../../components/loader";
import Pagination from "../../components/pagination";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import SidebarItem from "../../components/sidebarItem";
import { AiOutlineCopy, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import toast from "react-hot-toast";
import { closePopupMenu } from "../../utils/closePopupMenu";
import { HiDotsVertical } from "react-icons/hi";
import Back from "../../components/back";
import {
  deleteInfluencerGroups,
  getInfluencerGroups,
} from "../../controllers/influencerGroupsController";
import { BsPeople } from "react-icons/bs";

const InfluencersGroup = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(8);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [count, setCount] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);
  useEffect(() => {
    closePopupMenu(dropdownRef, () => {
      setShowOptions(false);
    });
  }, [dropdownRef]);
  useEffect(() => {
    getData();
  }, [page, keyword]);
  const getData = () => {
    let path = `?limit=${limit}&page=${page}&keyword=${keyword}`;
    getInfluencerGroups(path).then((response) => {
      const rows = response.data.body.rows;
      const count = response.data.body.count;
      setData(rows);
      setCount(count);
      setLoading(false);
    });
  };
  return loading ? (
    <Loader />
  ) : (
    <div>
      <Back />
      <div className="flex justify-between items-start">
        <div className="space-y-1">
          <h1 className="text-2xl 2xl:text-xl font-bold">Influencer Group</h1>
          <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
            Manage influencer groups Below
          </p>
        </div>
        <button
          onClick={() => {
            navigate("/add-influencer-group");
          }}
          className="py-2 px-6 font-semibold rounded-lg bg-primary text-white"
        >
          Add Group
        </button>
      </div>
      <div className="bg-white  dark:bg-darkLight rounded-xl mt-4 ">
        <div className="bg-background dark:bg-black rounded-t-2xl bg-opacity-40 dark:bg-opacity-10 px-6 items-center py-4 flex justify-between">
          <h1 className="font-bold text-lg">Groups ({count})</h1>
          <input
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            className="search-style"
            placeholder="Search here"
          />
        </div>
        <div className="px-6 py-5">
          <table className="">
            <thead>
              <tr>
                <th className="">Created At</th>
                <th className="">Name</th>
              </tr>
            </thead>
            <tbody className="mt-2">
              {data.map((item) => {
                return (
                  <tr key={item.uuid}>
                    <td className="">
                      {moment(item.createdAt).format("yyy,MMM DD")}
                    </td>
                    <td className="">{item.name}</td>
                    <td className="">
                      <div className="relative">
                        <HiDotsVertical
                          className=" cursor-pointer"
                          onClick={() => {
                            if (showOptions == false) {
                              setSelectedItem(item);
                              setShowOptions(!showOptions);
                            }
                          }}
                        />
                        {showOptions == true &&
                          selectedItem.uuid == item.uuid && (
                            <div ref={dropdownRef} className="popup-style">
                              <SidebarItem
                                isSidebar={false}
                                icon={<BsPeople />}
                                path={`/`}
                                onClick={() => {
                                  navigate(
                                    `/group-influencers/?uuid=${item.uuid}`
                                  );
                                }}
                                title={"Group Influencers"}
                              />
                              <SidebarItem
                                isSidebar={false}
                                icon={<AiOutlineDelete />}
                                onClick={() => {
                                  toast.success("Deleted successfully");
                                  deleteInfluencerGroups(item.uuid).then(
                                    (res) => {
                                      getData();
                                      setShowOptions(false);
                                      setSelectedItem(null);
                                    }
                                  );
                                }}
                                title={"Delete Group"}
                              />
                            </div>
                          )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            limit={limit}
            count={count}
            setPage={setPage}
            page={page}
          />
        </div>
      </div>
    </div>
  );
};

export default InfluencersGroup;
