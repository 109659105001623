import { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";

const SidebarGroupItem = ({ icon, label, path, children = [{}] }) => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(path == pathname);
  const navigate = useNavigate();
  return (
    <div className=" ">
      <div
        onClick={() => {
          setOpen(!open);
        }}
        className="flex justify-between px-4 items-center font-medium cursor-pointer hover:bg-white hover:bg-opacity-10 py-2 rounded-lg transition-all duration-200"
      >
        <div className="flex items-center space-x-3 ">
          <div className="text-xl"> {icon}</div>
          <p>{label}</p>
        </div>
        {open ? <BsChevronUp /> : <BsChevronDown />}
      </div>
      {open && (
        <div className="pl-12 py-3 space-y-2 ">
          {children.map((item) => {
            return (
              <div
                className="text-white text-opacity-75 hover:text-opacity-100 cursor-pointer transition-all duration-200"
                onClick={() => {
                  navigate(item.path);
                }}
              >
                {item.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SidebarGroupItem;
