import { useEffect } from "react";
import { getFacebookLink } from "../../controllers/userController";
import LoginPage from "../auth/loginPage";
import { extractAccessToken } from "../../utils/extractAccessToken";

const InfluencerAccounts = () => {
  useEffect(() => {
    console.log(extractAccessToken());
  });
  return (
    <div>
      <h1 className="font-bold text-xl mb-1">Social media accounts</h1>
      <p className="text-muted">
        Link your social media accounts so that we can track stats (eg:
        followers, views etc)
      </p>
      <div className="space-y-4 mt-8">
        <div className="flex items-center space-x-12  w-6/12 justify-between">
          <div className="flex items-center space-x-2">
            <img
              className="w-12"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/cd/Facebook_logo_%28square%29.png/640px-Facebook_logo_%28square%29.png"
            />
            <h1>Facebook Account</h1>
          </div>
          <button
            onClick={() => {
              getFacebookLink("http://localhost:3000/influencer-accounts").then(
                (res) => {
                  console.log(res.data.body.loginUrl);
                  window.location.href = res.data.body.loginUrl;
                }
              );
            }}
            className="bg-primary py-2 px-6 rounded-lg text-white font-semibold"
          >
            Link Account
          </button>
        </div>
        <div className="flex items-center space-x-12 w-6/12 justify-between  ">
          <div className="flex items-center space-x-2">
            <img
              className="w-12"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png"
            />
            <h1>Instagram Account</h1>
          </div>
          <button className="bg-primary py-2 px-6 rounded-lg text-white font-semibold">
            Link Account
          </button>
        </div>
        <div className="flex items-center space-x-12 w-6/12 justify-between  ">
          <div className="flex items-center space-x-2">
            <img
              className="w-12"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/X_logo.jpg/1200px-X_logo.jpg"
            />
            <h1>X Account</h1>
          </div>
          <button className="bg-primary py-2 px-6 rounded-lg text-white font-semibold">
            Link Account
          </button>
        </div>
        <div className="flex items-center space-x-12 w-6/12 justify-between  ">
          <div className="flex items-center space-x-2">
            <img
              className="w-12"
              src="https://cdn.pixabay.com/photo/2021/06/15/12/28/tiktok-6338429_1280.png"
            />
            <h1>Tiktok Account</h1>
          </div>
          <button className="bg-primary py-2 px-6 rounded-lg text-white font-semibold">
            Link Account
          </button>
        </div>
        <div className="flex items-center space-x-12 w-6/12 justify-between  ">
          <div className="flex items-center space-x-2">
            <img
              className="w-12"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_4N37TIgWC_QLpspNwGddZH8DhzljeYMFnA&s"
            />
            <h1>Youtube Account</h1>
          </div>
          <button className="bg-primary py-2 px-6 rounded-lg text-white font-semibold">
            Link Account
          </button>
        </div>
      </div>
    </div>
  );
};

export default InfluencerAccounts;
