import { useContext, useEffect, useRef, useState } from "react";
import { getPublishers, getUsers } from "../../controllers/userController";
import Spinner from "../../components/spinner";
import Loader from "../../components/loader";
import Pagination from "../../components/pagination";
import { useNavigate, useParams } from "react-router-dom";
import SidebarItem from "../../components/sidebarItem";
import { BsSignpost2, BsTrash } from "react-icons/bs";
import { closePopupMenu } from "../../utils/closePopupMenu";
import { HiDotsVertical } from "react-icons/hi";

import moment from "moment";
import toast from "react-hot-toast";

import { useGetParams } from "../../utils/getParams";

import { MdInsertChartOutlined } from "react-icons/md";
import {
  deleteTraditionalCampaignReport,
  getTraditionalCampaignReport,
  getTraditionalCampaignReports,
} from "../../controllers/traditionalCampaignReportController";
import Back from "../../components/back";
import { UserContext } from "../../layouts/dashboardLayout";
import { AiOutlineEdit } from "react-icons/ai";

const TraditionalCampaignReport = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { user } = useContext(UserContext);

  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(8);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [count, setCount] = useState(0);
  const params = useGetParams();
  const [selectedItem, setSelectedItem] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);
  useEffect(() => {
    closePopupMenu(dropdownRef, () => {
      setShowOptions(false);
    });
  }, [dropdownRef]);
  useEffect(() => {
    getData();
  }, [page, keyword]);
  const getData = () => {
    let path = `${params.uuid}/?limit=${limit}&page=${page}&keyword=${keyword}`;
    getTraditionalCampaignReports(path).then((response) => {
      console.log(response);
      const rows = response.data.body.rows;
      const count = response.data.body.count;
      console.log(rows);
      setData(rows);
      setCount(count);
      setLoading(false);
    });
  };
  return loading ? (
    <Loader />
  ) : (
    <div>
      <Back />
      <div className="flex justify-between items-start">
        <div className="space-y-2">
          <h1 className="text-2xl 2xl:text-xl font-bold">Campaign Report</h1>
          <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
            Manage {params.name} report Below
          </p>
        </div>
        <div className="flex space-x-2">
          <button
            onClick={() => {
              navigate(`/traditional-campaign-stats/?uuid=${params.uuid}`);
            }}
            className="py-2 px-6 font-semibold rounded-lg bg-opacity-10 text-primary"
          >
            View on Graphs
          </button>
          {user.role == "admin" && (
            <button
              onClick={() => {
                navigate(`/add-campaign-report/?uuid=${params.uuid}`);
              }}
              className="py-2 px-6 font-semibold rounded-lg bg-primary text-white"
            >
              Add Report
            </button>
          )}
        </div>
      </div>
      <div className="bg-white  dark:bg-darkLight rounded-xl mt-4 ">
        <div className="bg-background dark:bg-black rounded-t-2xl bg-opacity-40 dark:bg-opacity-10 px-6 items-center py-4 flex justify-between">
          <h1 className="font-bold text-lg">Records ({count})</h1>
          <input
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            className="search-style"
            placeholder="Search here"
          />
        </div>
        <div className="px-6 py-5">
          <table className="">
            <thead>
              <tr>
                <th className="">Recorded At</th>
                <th className="">AVE</th>
                <th className="">Clicks</th>
                <th className="">Impressions</th>
                <th className="">Spent</th>
                <th className="">Channel</th>
              </tr>
            </thead>
            <tbody className="mt-2">
              {data.map((item) => {
                return (
                  <tr key={item.uuid}>
                    <td className="">
                      {moment(item.recordedAt).format("yyy, MMM DD")}
                    </td>
                    <td className="">{item.AVE}</td>
                    <td className="">{item.clicks || 0}</td>
                    <td className="">{item.impressions}</td>
                    <td className="">${item.spent}</td>
                    <td className="">
                      {" "}
                      {item.TraditionalCampaignChannel.Channel.name}
                    </td>

                    <td className="">
                      {user.role == "admin" && (
                        <div>
                          <div className="relative">
                            <HiDotsVertical
                              className=" cursor-pointer"
                              onClick={() => {
                                if (showOptions == false) {
                                  setSelectedItem(item);
                                  setShowOptions(!showOptions);
                                }
                              }}
                            />
                            {showOptions == true &&
                              selectedItem.uuid == item.uuid && (
                                <div ref={dropdownRef} className="popup-style">
                                  <SidebarItem
                                    isSidebar={false}
                                    icon={<AiOutlineEdit />}
                                    path={`/edit-traditional-campaign-report/?uuid=${item.uuid}`}
                                    title={"Edit Report"}
                                  />
                                  <SidebarItem
                                    isSidebar={false}
                                    icon={<BsTrash />}
                                    onClick={() => {
                                      toast.success("Deleted Successfully");
                                      deleteTraditionalCampaignReport(
                                        item.uuid
                                      ).then((res) => {
                                        setShowOptions(false);
                                        setSelectedItem(false);
                                        getData();
                                      });
                                    }}
                                    title={"Delete Data"}
                                  />
                                </div>
                              )}
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            limit={limit}
            count={count}
            setPage={setPage}
            page={page}
          />
        </div>
      </div>
    </div>
  );
};

export default TraditionalCampaignReport;
