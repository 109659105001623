import React, { useState } from "react";
import TextForm from "../textForm";
import SubmitButton from "../submitButton";
import {
  addInfluencerBrandMention,
  deleteInfluencerBrandMention,
} from "../../controllers/brandMentionController";
import toast from "react-hot-toast";
import { AiOutlineClose } from "react-icons/ai";

const BrandMentionsForm = ({ user, getData }) => {
  // State to manage the input fields
  let [formData, setFormData] = useState({
    brandLogo: "",
    brandName: "",
    mentions: "",
    effectiveness: "",
    user_uuid: user.uuid,
  });

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const reset = () => {
    setFormData({
      brandLogo: "",
      brandName: "",
      mentions: "",
      effectiveness: "",
      user_uuid: user.uuid,
    });
  };
  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Brand Mention Data:", formData);
    addInfluencerBrandMention(formData).then((res) => {
      toast.success("Added successfully");
      getData();
      reset();
    });
    // Here you can send `formData` to an API or handle the data accordingly
  };

  return (
    <div className="w-full bg-white p-8 rounded-lg mt-4">
      <form onSubmit={handleSubmit} className="">
        <h1 className="font-bold text-lg">Brand Mentions</h1>
        <p className="text-sm text-muted mb-4">Add brand mentions</p>
        <div className="grid-cols-3 gap-2 grid">
          <TextForm
            label={"Brand Logo"}
            name="brandLogo"
            value={formData.brandLogo}
            onChange={handleInputChange}
          />
          <TextForm
            label={"Brand Name"}
            name="brandName"
            value={formData.brandName}
            onChange={handleInputChange}
          />
          <TextForm
            label={"Mentions"}
            name="mentions"
            value={formData.mentions}
            onChange={handleInputChange}
          />
          <TextForm
            label={"Effectiveness"}
            name="effectiveness"
            value={formData.effectiveness}
            onChange={handleInputChange}
          />
        </div>
        <SubmitButton text={"Add Mention"} />
      </form>
      <h1 className="font-bold mt-8 mb-2">Added mentions</h1>
      {user.InfluencerBrandMentions.length < 1 && (
        <div className="text-muted">No Data</div>
      )}
      {user.InfluencerBrandMentions.map((item) => {
        return (
          <div className="flex">
            <div className="flex items-center space-x-6  p-2 rounded-lg shadow w-full">
              <img className="size-8 rounded-full" src={item.brandLogo} />
              <h1>{item.brandName}</h1>
              <div>mentions: {item.mentions}</div>
              <div>Effectiveness: {item.mentions}</div>
              <AiOutlineClose
                className="text-muted hover:text-red-400 cursor-pointer"
                onClick={() => {
                  deleteInfluencerBrandMention(item.uuid).then((res) => {
                    toast.success("Removed successfully");
                    getData();
                  });
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BrandMentionsForm;
