import { useState } from "react";
import { sendCode } from "../../controllers/authController";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import TextForm from "../../components/textForm";
import { registerUser } from "../../controllers/userController";
import SelectForm from "../../components/selectForm";

const RegisterPage = () => {
  const [Loading, setLoading] = useState(false);
  const [isEmail, setIsEmail] = useState(true);
  const [isActive, setIsActive] = useState(false);

  const navigate = useNavigate();
  return (
    <div className="w-screen h-screen bg-background  flex justify-center items-center text-dark">
      <div className=" h-screen rounded-xl bg-white items-center flex">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const payload = {
              role: e.target.role.value,
              name: e.target.name.value,
              email: e.target.email.value,
              phone: e.target.phone.value,
            };
            setLoading(true);
            registerUser(payload)
              .then((data) => {
                toast.success("Sent successfully");
                navigate(`/confirm/${payload.email}`);
                setLoading(false);
              })
              .catch((err) => {
                setLoading(false);
                const message = err.response.data.message;
                toast.error(message);
              });
          }}
          className="w-full md:w-6/12 py-24  flex flex-col items-start px-8 md:px-48 2xl:px-72 justify-center"
        >
          <h1 className="font-bold text-2xl md:text-3xl mb-2">
            Create an account
          </h1>
          <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
            Create a new account
          </p>
          <div className="space-y-2 mt-12 w-full">
            <div className="flex flex-col space-y-2 w-full">
              <SelectForm
                name={"role"}
                options={["Influencer", "Publisher"]}
                values={["influencer", "publisher"]}
                placeholder={"Enter full name"}
                label={"Register as"}
              />
              <TextForm
                name={"name"}
                placeholder={"Enter full name"}
                label={"Full Name"}
              />
              <TextForm
                name={"email"}
                placeholder={"Enter email address"}
                label={"Email Address"}
              />
              <TextForm
                name={"phone"}
                placeholder={"Enter phone number"}
                label={"Phone number"}
              />
            </div>
          </div>
          <button
            type="submit"
            className="bg-primary py-3 transition-all w-full font-semibold rounded-lg mt-4 text-white hover:bg-opacity-85  "
          >
            {Loading ? "Sending verification code..." : "Create an account"}
          </button>
          <div className="flex space-x-1 text-sm mt-2">
            <p>Already registered ?</p>
            <p
              onClick={() => {
                navigate("/login");
              }}
              className="text-primary cursor-pointer"
            >
              Login
            </p>
          </div>
        </form>
        <div className="w-6/12 hidden md:block  ">
          <div className=" ">
            <img className="" src="/loginimg.png" />
            <h1 className="font-bold text-2xl mt-4 pe-16">
              A Few clicks away from publishing your ads
            </h1>
            <p className="text-muted text-sm pe-16">
              With just a few clicks, you can easily publish your ads and share
              them with your target audience. Our streamlined process ensures
              that creating, managing, and launching your ads is quick and
              hassle-free. Whether you're promoting products, services, or
              events, we make it simple to get your message out to the right
              people in no time. Take the stress out of advertising and focus on
              growing your reach effortlessly!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
