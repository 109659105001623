const { getTokens } = require("../utils/authStore");
const { app } = require("./authController");

export const addInfluencerGroups = async (data) => {
  return await app.post("/influencer-groups/", data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const getInfluencerGroups = async (path) => {
  return await app.get(`/influencer-groups/${path}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const getInfluencerGroup = async (uuid) => {
  return await app.get(`/influencer-groups/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const editInfluencerGroups = async (uuid, data) => {
  return await app.patch(`/influencer-groups/${uuid}`, data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const deleteInfluencerGroups = async (uuid) => {
  return await app.delete(`/influencer-groups/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
