import React, { useState, useEffect } from "react";
import TextForm from "../textForm";
import { AiOutlineClose } from "react-icons/ai";
import SubmitButton from "../submitButton";
import { editInfluencerDetail } from "../../controllers/influencerDetailController";
import toast from "react-hot-toast";

const AudienceForm = ({ user, getData }) => {
  const [audiences, setAudiences] = useState({
    audiencesByGender: [],
    audienceByCountry: [],
    audienceByCity: [],
    audienceByAge: [],
  });

  useEffect(() => {
    if (user.InfluencerDetail) {
      setAudiences({
        audiencesByGender: Object.entries(
          user.InfluencerDetail.audiencesByGender || {}
        ),
        audienceByCountry: Object.entries(
          user.InfluencerDetail.audienceByCountry || {}
        ),
        audienceByCity: Object.entries(
          user.InfluencerDetail.audienceByCity || {}
        ),
        audienceByAge: Object.entries(
          user.InfluencerDetail.audienceByAge || {}
        ),
      });
    }
  }, [user]);

  const handleAddEntry = (category) => {
    setAudiences((prev) => ({
      ...prev,
      [category]: [...prev[category], ["", ""]], // Add an empty entry (key, value)
    }));
  };

  const handleRemoveEntry = (category, index) => {
    setAudiences((prev) => {
      const updatedCategory = [...prev[category]];
      updatedCategory.splice(index, 1); // Remove entry at the given index
      return {
        ...prev,
        [category]: updatedCategory,
      };
    });
  };

  const handleInputChange = (category, index, field, value) => {
    setAudiences((prev) => {
      const updatedCategory = [...prev[category]];
      const updatedEntry = [...updatedCategory[index]];

      if (field === "key") {
        updatedEntry[0] = value; // Update the key
      } else {
        updatedEntry[1] = value; // Update the value
      }

      updatedCategory[index] = updatedEntry; // Replace the entry with updated key/value

      return {
        ...prev,
        [category]: updatedCategory,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Convert array to an object for each category
    const convertedAudiences = Object.keys(audiences).reduce(
      (acc, category) => {
        const categoryObj = audiences[category].reduce((obj, [key, value]) => {
          obj[key] = value; // Convert [key, value] into key: value
          return obj;
        }, {});
        acc[category] = categoryObj;
        return acc;
      },
      {}
    );
    editInfluencerDetail(user.InfluencerDetail.uuid, convertedAudiences).then(
      (res) => {
        getData();
        toast.success("Saved successfully");
      }
    );
    console.log("Converted Audiences:", convertedAudiences);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full bg-white p-8 rounded-lg mt-4"
    >
      <h1 className="font-bold text-lg">Audience Details</h1>
      <p className="text-sm text-muted mb-4">
        Add or edit audience data by category
      </p>
      {Object.keys(audiences).map((category) => (
        <div key={category} className="mb-6">
          <h2 className="font-bold text-md mb-2">
            {category.replace(/([A-Z])/g, " $1").trim()}
          </h2>
          <div className="space-y-4">
            {audiences[category].map(([key, value], index) => (
              <div
                key={index}
                className="grid grid-cols-3 gap-4 items-center justify-center "
              >
                <TextForm
                  label={"Label"}
                  placeholder={"Enter key"}
                  name={`${category}[${index}].key`}
                  inputType={"text"}
                  value={key}
                  onChange={(e) =>
                    handleInputChange(category, index, "key", e.target.value)
                  }
                />
                <TextForm
                  label={"Value"}
                  placeholder={"Enter value"}
                  name={`${category}[${index}].value`}
                  inputType={"text"}
                  value={value}
                  onChange={(e) =>
                    handleInputChange(category, index, "value", e.target.value)
                  }
                />
                <button
                  type="button"
                  onClick={() => handleRemoveEntry(category, index)}
                  className="text-muted mt-6 hover:text-red-400 rounded"
                >
                  <AiOutlineClose />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => handleAddEntry(category)}
              className="px-3 py-1 bg-muted text-white rounded-lg"
            >
              Add Entry
            </button>
          </div>
        </div>
      ))}
      <SubmitButton text={"Save changes"} />
    </form>
  );
};

export default AudienceForm;
