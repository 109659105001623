import { Outlet, useNavigate } from "react-router-dom";

const AffiliateLayout = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="bg-white py-4 w-screen fixed flex justify-between px-12 shadow z-50">
        <img className="h-8" src="/logo.svg" />
        <div className="flex items-center space-x-2 ">
          <h1>Mangi Wakihaya</h1>
          <div className="size-8 bg-gray-300 rounded-full">
            <img
              className="size-8 rounded-full object-cover"
              src="https://static.vecteezy.com/system/resources/thumbnails/032/712/071/small_2x/a-young-african-american-man-in-a-brown-t-shirt-poses-against-a-gray-background-man-looking-at-camera-ai-generative-photo.jpg"
            />
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="w-[18%] bg-gray-100 h-screen fixed pt-24 px-12">
          {[
            { title: "My Profile", path: "/affiliate-program" },
            {
              title: "Followed Companies",
              path: "/affiliate-program/companies",
            },
            {
              title: "Notifications",
              path: "/affiliate-program/notifications",
            },
          ].map((item) => {
            return (
              <div
                onClick={() => {
                  navigate(item.path);
                }}
                className="py-4 border-b hover:text-primary transition-all duration-200 border-b-gray-200 cursor-pointer"
              >
                {item.title}
              </div>
            );
          })}
        </div>
        <div className="w-[82%] ms-auto pt-24 px-12">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AffiliateLayout;
