import { useNavigate } from "react-router-dom";
import TextForm from "../../components/textForm";
import { BsChevronBarDown, BsChevronDown, BsChevronUp } from "react-icons/bs";
import { useState } from "react";

const AffiliateProgramProfile = () => {
  const navigate = useNavigate();
  const [selectedCompany, setSelectedCompany] = useState(null);
  return (
    <div>
      <div className="w-6/12">
        <h1 className="text-2xl font-bold">My Profile</h1>
        <p className="text-muted text-sm mt-2">
          We're here for you however you shop. Make Kwanza your own. Starting
          with some basic info.
        </p>
        <TextForm label={"Name"} />
        <TextForm label={"Email"} />
        <TextForm label={"Phone"} />
        <div className="bg-primary py-3 font-medium px-6 text-center text-white rounded-lg mt-4">
          Update
        </div>
        <h1 className="text-2xl font-bold mt-12">Campanies</h1>
        <p className="text-muted text-sm mt-0">Click to view details</p>
        <div className="pb-24">
          {[
            {
              company: "Vodacom",
              description: "Telcom company",
              image:
                "https://solomon.co.tz/wp-content/uploads/2018/12/vodacom.png",
              link: "",
            },
            {
              company: "Stanbic Bank",
              description: "Microfinance Company ",
              image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSf3HlbJCSYFF5JUL37iOlu_BEFKYD_GK5JVQ&s",
              link: "",
            },
          ].map((item) => {
            return (
              <div
                onClick={() => {
                  if (selectedCompany == item.company) {
                    setSelectedCompany(null);
                  } else {
                    setSelectedCompany(item.company);
                  }
                }}
                className=" mt-4"
              >
                <div className="flex space-x-2 mt-2 justify-between items-center cursor-pointer">
                  <div className="flex space-x-2">
                    <img className="size-12" src={item.image} />
                    <div>
                      <h1 className="text-lgs font-bold">{item.company}</h1>
                      <p className="text-sm text-muted ">{item.description}</p>
                    </div>
                  </div>
                  {selectedCompany == item.company ? (
                    <BsChevronUp className="text-muted ml-auto" />
                  ) : (
                    <BsChevronDown className="text-muted ml-auto" />
                  )}
                </div>
                {selectedCompany == item.company && (
                  <div>
                    <div className="">
                      <h1 className="text-xl font-bold mt-4">
                        Share the link. Reap the rewards.
                      </h1>
                      <p className="text-muted text-sm mt-1">
                        Share this link with friends and you can get 500 for
                        each friend that signs up. Because it pays to share.
                      </p>
                    </div>
                    <div className="flex  space-x-2 ">
                      <input value={item.link} className="input-style" />
                      <div>
                        <button className="py-3 px-6 bg-primary rounded-lg text-white font-medium">
                          Copy
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AffiliateProgramProfile;
