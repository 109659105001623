import { useEffect, useState } from "react";
import {
  editContract,
  getContract,
} from "../../controllers/contractControllers";
import { useParams } from "react-router-dom";
import Spinner from "../../components/spinner";
import { BsPlus } from "react-icons/bs";
import moment from "moment";
import SubmitButton from "../../components/submitButton";
import toast from "react-hot-toast";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import TextForm from "../../components/textForm";
import {
  AiFillCloseCircle,
  AiOutlineClose,
  AiOutlineLock,
} from "react-icons/ai";

const InfluencerContract = () => {
  const [data, setData] = useState(null);
  const [handles, setHandles] = useState([""]);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [opened, setOpened] = useState(false);
  const { uuid } = useParams();

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    getContract(uuid).then((res) => {
      setData(res.data.body);
      //   console.log(res.data.body.recipientSignature.handles);
      let signature = res.data.body.recipientSignature;
      setHandles(res.data.body.recipientSignature?.handles || [""]);

      setLoading(false);
    });
  };
  return loading ? (
    <Spinner />
  ) : data.status == "Signed" ? (
    <div className="">
      <div className="bg-background w-screen h-screen justify-center items-center flex flex-col">
        <div className="flex justify-center mb-4">
          <AiFillCloseCircle className="text-6xl text-red-500" />
        </div>
        <h1 className="text-xl font-semibold text-center">Already Signed </h1>
        <p className="text-sm text-muted mb-4 mt-1 text-center px-8">
          This document is already signed, check your email
        </p>
      </div>
    </div>
  ) : !opened ? (
    <div className="bg-background w-screen h-screen justify-center items-center flex flex-col">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const password = e.target.password.value;
          if (password == data.password) {
            setOpened(true);
          } else {
            toast.error("Wrong password");
          }
        }}
        className=" w-11/12  md:w-4/12 2xl:w-2/12"
      >
        <div className="flex justify-center mb-8">
          <img src="/Kwanza 1.svg" className="h-10" />
        </div>
        <h1 className="text-xl font-semibold text-center">
          Unlock Document {data.status}{" "}
        </h1>
        <p className="text-sm text-muted mb-4 mt-1 text-center px-8">
          This document is password protected, enter password to open
        </p>
        <TextForm
          label={"Password"}
          name={"password"}
          inputType={"password"}
          placeholder={"Write password here"}
        />
        <div className="flex justify-center">
          <SubmitButton text={"Unlock Document"} />
        </div>
      </form>
    </div>
  ) : (
    <div className="bg-slate-800">
      <div className="bg-white max-w-11/12 md:max-w-4xl px-4 md:px-24 py-12 mx-auto  text-black">
        <div className="flex justify-between items-center">
          <div className="inline-block border-0 transform rotate-0 translate-z-0">
            <img src="/image2.png" alt="" className="inline-block h-12" />
          </div>
          <div className="inline-block border-16 border-white transform rotate-0 translate-z-0">
            <img src="/image1.png" alt="" className="inline-block h-9" />
          </div>
        </div>
        <p className="text-center font-bold mt-4">
          Smart Foundry Limited Influencer’s Terms of Use Agreement
        </p>
        <p className="mt-4">
          This document spells out specific terms for the engagement between{" "}
          <span className="font-bold">Smart Foundry Limited</span> as an agency
          with{" "}
          <span className="font-bold border-b-2 border-black ">
            {data.recipient}
          </span>{" "}
          as an influencer for the purpose of being an influencer for different
          campaigns that will be done through Kwanza Media Trading Desk and
          annexed to this agreement accordingly as and when they happen.
        </p>
        <p className="mt-4 ">Influencer's social media profiles links:</p>
        <ol className="flex flex-col w-6/12 list-decimal list list-inside">
          {handles.map((item, index) => {
            return (
              <li key={index} className="flex items-center space-x-2">
                <input
                  defaultValue={item}
                  onChange={(e) => {
                    let availableHandles = [...handles];
                    availableHandles[index] = e.target.value;
                    setHandles(availableHandles);
                  }}
                  className="py-1 border-0 ring-0 focus:ring-0 focus:border-0 focus:border-b-2 border-b-2 border-dashed focus:border-primary"
                />
                <button
                  onClick={() => {
                    // Remove the handle at the current index
                    let updatedHandles = handles.filter((_, i) => i !== index);
                    setHandles(updatedHandles);
                  }}
                  className="text-muted text-opacity-50 hover:text-red-500"
                >
                  <AiOutlineClose />
                </button>
              </li>
            );
          })}
        </ol>
        <div
          onClick={() => {
            setHandles([...handles, ""]);
          }}
          className="flex items-center  text-primary text-sm cursor-pointer font-bold mt-4  "
        >
          <BsPlus className="text-xl  " />
          <h1>Add Link</h1>
        </div>
        <ol className=" list-decimal  space-y-4 pl-5 mt-5">
          <li className="">Smart Foundry Influencer Terms of Use Agreement:</li>
          <div className="space-y-3">
            <p className="">
              Welcome to the Smart Foundry Limited Influencer Program. The term
              “Smart Foundry” or “Kwanza” or “us” or “we” or “our” refers to
              Smart Foundry Limited. The term “you” or “Influencer” or “content
              creator” or “manager” or “representative” refers to the individual
              who is hired to be influencer and/or officially representing
              influencer(s).
            </p>
            <p className="">
              Smart Foundry Limited provides creators management services to its
              clients (“Clients”) by engaging Influencers to post client-related
              content on Influencers’ personal social media accounts. Each
              marketing campaign for a client is called a “Campaign.”
            </p>
            <p className="">
              Each campaign that influencer will be engaged will have separate
              terms annexed to this agreement as in number of posts, frequency
              of posting, type of posts, period of engagement and amount to be
              paid.
            </p>
          </div>
          <li className="">Acceptance of Agreement.</li>
          <div className="space-y-3">
            <p className="">
              2.1 By accepting to be an Influencer and/or accepting advertising
              assignment from Smart Foundry Limited, you agree to comply with
              and be bound by the terms and conditions outlined in this Smart
              Foundry Limited Influencer Terms of Use Agreement (“Agreement”) as
              well as the general Terms of Use governing the campaign.
            </p>
            <p className="">
              2.2 You agree that we may make changes to this Agreement and/or
              its annexes at any time and that any amendment to the Agreement
              will take effect upon your notice of such amendment. If we notify
              you of any change to this Agreement that you do not accept, this
              contract shall immediately be terminated, along with any other
              engagement you have with Smart Foundry Limited as an influencer
            </p>
          </div>
          <li className="">Influencer-Created Intellectual Property.</li>
          <div className="space-y-3">
            <p className="">
              3.1 In your performance of a Job as an influencer, you will create
              and post content related to the Job on your personal social media
              accounts. Such content is known as “Influencer Content” and may
              include photographs, video, original artwork, text, intellectual
              property, and any other type of content or media that influencer
              will create or be posted on your social media accounts.
            </p>
            <p className="">
              3.2 You hereby acknowledge and agrees that Smart Foundry Limited
              and their clients is and shall be the sole owner of all right,
              title and interest to any and all Influencer Content perpetually
              (such assigned Influencer Content is referred to as the “Work
              Product”), of which Work Product shall be treated as a work made
              for hire under the Copyright Act.
            </p>
          </div>
          <li className="">Rules of Engagement.</li>
          <div className="space-y-3">
            <p className="">
              4.1 As an Influencer and in the performance of any activity(s),
              influencer represent and warrant that, in addition to the
              Restrictions and Prohibitions on Use in Smart Foundry General
              Terms of Use which will be on (kwanza.co.tz), influencer will
              comply with all of the following rules of conduct:
            </p>
            <ol className="">4.1.1 Postings</ol>
            <p className="">
              Influencer’s social media posts related to this contract will
              comply with the specifications set forth in the contract duties
              and its annexes and any subsequent directions from Smart Foundry
              regarding the contact.
            </p>
            <p className="">
              Influencer will not post anything on any social media account or
              on any other platform or media to disparage either Smart Foundry
              or any Client you are currently working for or you have performed
              any activity for.
            </p>
            <p className="">
              Any post influencer makes on a social media account will not
              contain any content that contains any misstatements of law,
              falsehood, obscenity, severe profanity, or is unlawful, obscene,
              defamatory, libellous, threatening, pornographic, bullying,
              harassing, hateful, racially or ethnically offensive, or
              encourages conduct that would be considered a criminal offense,
              give rise to civil liability, violate any law, or is otherwise
              inappropriate.
            </p>
            <ol className="">
              <li className="">4.1.2 Disclaimer of Warranty</li>
            </ol>
            <p className="">
              Smart Foundry Limited provides the influencer program and duties
              to you “as-is” without any warranties or representations of any
              kind, whether statutory, express or implied, including but not
              limited to, warranties of title, no infringement, merchantability,
              fitness for a particular purpose, accuracy, completeness or any
              results to be achieved here from.
            </p>
            <ol className="">
              <li className="">4.1.3 Limitation of Liability</li>
            </ol>
            <p className="">
              Smart Foundry Limited and any affiliated party shall not be liable
              for any loss, injury, claim, liability, or damage of any kind
              resulting in any way from influencer’s performing her/his duties
              or any violation by a client in the course of performing your job.
            </p>
            <ol className="">
              <li className="">4.1.4 Independent Contractor</li>
            </ol>
            <p className="">
              In the performance of the parties’ duties and obligations under
              this Agreement, it is mutually understood and agreed that
              Influencer is and will be an independent contractor of Smart
              Foundry Limited and/or the Client. Nothing in this Agreement shall
              be construed as creating a partnership or joint venture between
              you and Smart Foundry, you and a Client, or Smart Foundry Limited
              and a Client. This is a contract for the purpose of amplifying the
              campaign messages and at no point should the influencer portray
              themselves as a Representative of Smart Foundry Limited or its
              clients.
            </p>
            <ol className="">
              <li className="">4.1.5 Termination of Contract</li>
            </ol>
            <p className="">
              In the event the influencer breaches any terms stipulated in this
              agreement and fail to correct within a period of 5 days to Smart
              Foundry Limited and its client’s satisfaction, Smart Foundry
              Limited will terminate the agreement by giving the influencer
              written notice. The influencer will incur the value of revenue
              loss for the remaining period of the assignment.
            </p>
          </div>
          <li className="">Confidentiality clause:</li>
          <p className="">
            You must not, without the prior written permission of Smart Foundry
            Limited, at any time during your appointment, or after it has ended,
            make or permit any unauthorized use or unauthorized disclosure or
            any information which concerns the work, affairs or objects of the
            Company, or has or which may come to your knowledge during the
            course of your employment, save for that information which is
            properly in the public domain. The terms of this Clause are timeless
            even after the expiry or termination of this Agreement, unless the
            Company releases your obligations in writing.
          </p>
          <li className="">Parties Duties.</li>
          <div>
            <p className="">6.1 Smart Foundry Limited:</p>
            <ol className=" list-item list-inside space-y-3 pl-4">
              <li className="">
                i. Prepare final reports which shall be sent to respective
                client’s according to client’s standards.
              </li>
              <li className="">
                ii. Guide the influencer in sharing content which represent well
                the client on influencer’s tone.
              </li>
              <li className="">
                iii. Prepare proposals and pitch for different campaigns and
                present the same to the client.
              </li>
              <li className="">
                iv. Represent influencer’s interest to the client accordingly.
              </li>
              <li className="">
                v. Whenever need arises train influencer on different marketing
                tactics.
              </li>
              <li className="">
                vi. Onboard influencer for any new campaign and ensure that
                influencer understand the campaign to achieve client’s
                expectations.
              </li>
              <li className="">
                vii. Upgrade and maintain Kwanza digital platform.
              </li>
            </ol>
          </div>
          <div>
            <p className="">6.2 Influencer’s Duties</p>
            <ol className=" list-item list-inside space-y-3 pl-4">
              <li className="">
                i. Create content (video, photo and text) which shall align with
                the client’s campaign according to annex A (Sent separate)
              </li>
              <li className="">
                ii. Post content on his/her social media accordingly to annex A
                (Sent separate).
              </li>
              <li className="">
                iii. Engage accordingly to ensure that the post gets highest
                level of engagement.
              </li>
              <li className="">
                iv. Share high level report to Smart Foundry accordingly.
              </li>
            </ol>
          </div>
          <li className="">Consideration and Terms of Payment</li>
          <div>
            {" "}
            <p className="">
              Subject to clause 6 and Annex A (Sent separate) of this agreement,
              Smart Foundry Limited and the Influencer will receive a revenue
              share of {data.kwanzaPercent}:{data.influencerPercent} in favor of
              the Influencer on client’s budget. Payment will be made in arrears
              after the work is completed, the report is approved by the client,
              and the relevant invoice is submitted to Smart Foundry Limited
            </p>
          </div>
        </ol>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const payload = {
              checked: true,
              signature: e.target.signature.value,
              signedBy: e.target.signedBy.value,
              date: e.target.date.value,
              handles: handles,
            };
            if (payload.signature === payload.signature.toUpperCase()) {
              setUploading(true);
              editContract(uuid, {
                status: "Signed",
                recipientSignature: payload,
              }).then((res) => {
                // getData();
                toast.success(
                  "Submitted successfully, check your email to download shared contract"
                );
                setUploading(false);
              });
            } else {
              toast.error("Use capital later to sign");
            }
          }}
        >
          <div className="flex space-x-2 items-center mt-6">
            <input
              type="checkbox"
              required
              defaultChecked={data.recipientSignature?.checked}
              className="w-5 h-5 rounded border-gray-300 text-primary checked:bg-primary focus:ring-primary hover:checked:bg-primary"
            />

            <h1>I agree to terms and conditions of this contract</h1>
          </div>
          <div className="mt-4 grid grid-cols-1 w-6/12 gap-6">
            {[
              {
                label: "Signed By",
                value: data.recipient,
                isDisabled: true,
                isSignature: false,
                name: "signedBy",
              },
              {
                label: "Use Capital Later to Sign",
                value: data.recipientSignature?.signature,
                isDisabled: false,

                isSignature: true,
                name: "signature",
              },
              {
                label: "Date",
                name: "date",
                isDisabled: true,

                value: moment(Date()).format("yyy, MMM DD"),
                isSignature: false,
              },
            ].map((item, index) => {
              return (
                <div
                  className="flex items-center space-x-2"
                  key={`key${index}`}
                >
                  <div className="w-3/12">{item.label}:</div>
                  <div
                    style={{
                      fontFamily: item.isSignature
                        ? "'Bonheur Royale', cursive"
                        : "",
                    }}
                    className={`${
                      item.isSignature ? "text-primary" : ""
                    } font-semibold`}
                  >
                    <input
                      defaultValue={item.value}
                      name={item?.name}
                      disabled={item.isDisabled}
                      required
                      className=" py-1 border-0 ring-0  focus:ring-0 focus:border-0 focus:border-b  border-b-2 border-dashed focus:border-primary"
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="pt-4">
            <SubmitButton loading={uploading} text={"Submit Contract"} />
          </div>
        </form>

        <div className="text-center mt-8">
          <p className="text-yellow-500">
            Smart Foundry Limited I P.O. Box 79375 I Dar es Salaam I Tanzania I
            +255 22 277 5801 I
          </p>
          <p className="text-yellow-500">info@smartfoundry.co</p>
          <p className="text-yellow-500">www.smartfoundry.co</p>
          <div className="inline-block border-0 transform rotate-0 translate-z-0"></div>
        </div>
      </div>
    </div>
  );
};

export default InfluencerContract;
