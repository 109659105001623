const { getTokens } = require("../utils/authStore");
const { app } = require("./authController");

export const addInfluencerDetail = async (data) => {
  return await app.post("/influencer-details/", data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const getInfluencerDetails = async (path) => {
  return await app.get(`/influencer-details/${path}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const getInfluencerDetailsStats = async () => {
  return await app.get(`/influencer-details/stats`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const getInfluencerDetail = async (uuid) => {
  return await app.patch(`/influencer-details/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const editInfluencerDetail = async (uuid, data) => {
  return await app.patch(`/influencer-details/${uuid}`, data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const deleteInfluencerDetail = async (uuid) => {
  return await app.delete(`/influencer-details/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
