import { useContext, useState } from "react";
import SubmitButton from "../../components/submitButton";
import TextForm from "../../components/textForm";
import { showError } from "../../utils/showError";
import toast from "react-hot-toast";
import SelectForm from "../../components/selectForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import { registerUser } from "../../controllers/userController";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Back from "../../components/back";
import { addWebsiteCategory } from "../../controllers/websiteCategoriesController";
import { addContract } from "../../controllers/contractControllers";

const AddContract = () => {
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();
  return (
    <div>
      <Back />
      <div className="space-y-1">
        <h1 className="text-2xl 2xl:text-xl font-bold">Add contract</h1>
        <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
          Enter contract informations below
        </p>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const payload = {
            recipient: e.target.recipient.value,
            email: e.target.email.value,
            password: e.target.password.value,
            type: e.target.type.value,
            kwanzaPercent: e.target.kwanzaPercent.value,
            influencerPercent: e.target.influencerPercent.value,
          };
          setUploading(true);
          addContract(payload)
            .then((data) => {
              toast.success("added successfully");
              navigate(-1);
              setUploading(false);
            })
            .catch((err) => {
              setUploading(false);
              showError(err);
            });
        }}
        className="bg-white dark:bg-darkLight py-12 rounded-xl mt-8 w-6/12 px-8"
      >
        <div className="space-y-1">
          <h1 className="text-lg font-bold">Add contract form</h1>
          <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
            All fields are required
          </p>
        </div>
        <div className="space-y-4 my-6 mb-8">
          <TextForm
            placeholder={"Enter recipient name"}
            name={"recipient"}
            required={true}
            label={"Recipient"}
          />
          <TextForm
            placeholder={"Enter recipient email"}
            name={"email"}
            required={true}
            label={"Recipient email"}
          />
          <TextForm
            placeholder={"Enter kwanza percent"}
            name={"kwanzaPercent"}
            required={true}
            label={"Kwanza Percent"}
          />
          <TextForm
            placeholder={"Enter influencer percent"}
            name={"influencerPercent"}
            required={true}
            label={"Influencer Percent"}
          />
          <TextForm
            placeholder={"Enter phone number"}
            name={"password"}
            required={true}
            label={"Recipient phone number"}
          />
          <SelectForm
            options={["Influencer"]}
            name={"type"}
            required={true}
            label={"Type of contract"}
          />
        </div>
        <SubmitButton loading={uploading} text={`Add contract`} />
      </form>
    </div>
  );
};

export default AddContract;
