const InfluencerNotificatoin = () => {
  return (
    <div>
      <div className="w-6/12">
        <div className="flex justify-between items-center border-b border-gray-100">
          <div>
            <h1 className="text-2xl font-bold">Notifications</h1>
            <p className="text-muted text-sm mt-1">
              Select features to get notifications
            </p>
          </div>
          <div className="w-4/12">
            <input placeholder="Search here" className="input-style " />
          </div>{" "}
        </div>
        <div className="pb-24">
          {[
            {
              title: "Droplist Price Drop Alerts",
              description:
                "Leave the price checking to us. Get notified when we detect a price drop on items you're watching from select stores.",
            },
          ].map((item) => {
            return (
              <div
                onClick={() => {
                  //   if (selectedCompany == item.company) {
                  //     setSelectedCompany(null);
                  //   } else {
                  //     setSelectedCompany(item.company);
                  //   }
                }}
                className=" mt-4"
              >
                <div className="flex space-x-2 mt-2 justify-between items-center cursor-pointer">
                  <div className="flex space-x-2">
                    <div>
                      <h1 className="text-lgs font-bold">{item.title}</h1>
                      <p className="text-sm text-muted ">{item.description}</p>
                    </div>
                  </div>
                  <input type="checkbox" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default InfluencerNotificatoin;
