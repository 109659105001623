import { AiOutlineDashboard, AiOutlineVideoCamera } from "react-icons/ai";
import SidebarItem from "../sidebarItem";
import { SiGoogleadsense } from "react-icons/si";
import { LiaBuysellads, LiaFileInvoiceDollarSolid } from "react-icons/lia";
import {
  TbAB2,
  TbCashRegister,
  TbContract,
  TbLogs,
  TbReport,
  TbReportSearch,
} from "react-icons/tb";
import {
  BsBox2,
  BsFile,
  BsInstagram,
  BsPerson,
  BsSignpost2,
} from "react-icons/bs";
import { TiSocialInstagram } from "react-icons/ti";
import { MdPublic } from "react-icons/md";
import { RiAdvertisementLine, RiEarthLine } from "react-icons/ri";
import { LuLayoutDashboard, LuUsers } from "react-icons/lu";
import { FiBookOpen } from "react-icons/fi";
import { IoMdOptions } from "react-icons/io";
import {
  IoRemoveCircleOutline,
  IoSettings,
  IoSettingsOutline,
} from "react-icons/io5";
import { SlSettings } from "react-icons/sl";
import SidebarComponent from "../sidebarComponent";
import SidebarGroup from "../sidebarGroup";
import SidebarGroupItem from "../sidebarGroupItem";
import { HiOutlineClipboardDocument } from "react-icons/hi2";

const AdminSidebar = () => {
  return (
    <div className="px-4 justify-between  flex flex-col h-screen text-white text-opacity-[98]">
      <div className="flex flex-col  h-screen ">
        {/* <h1 className="mt-4  font-bold text-2xl 2xl:text-3xl px-4 text-white">
          Kwanza
        </h1> */}
        <div className="">
          <img src="/Kwanza 2.svg" className="h-8 mt-4" />

          <div className="pt-8 space-y-1">
            <SidebarComponent
              icon={<LuLayoutDashboard />}
              label={"Overview"}
              path="/"
            />
            <SidebarGroupItem
              icon={<BsPerson />}
              children={[
                { label: "Users", path: "/users" },
                { label: "Influencers", path: "/influencers" },
                { label: "Agencies", path: "/agencies" },
                { label: "Publishers", path: "/publishers" },
              ]}
              label={"Accounts"}
              path="/users"
            />
            <SidebarGroupItem
              icon={<TbReportSearch />}
              children={[
                { label: "Invoices", path: "/invoices" },
                { label: "Channels", path: "/channels" },
              ]}
              label={"Reports"}
              path="/reports"
            />
            <SidebarGroupItem
              icon={<HiOutlineClipboardDocument />}
              children={[
                { label: "Unapproved Zones", path: "/unapproved-zones" },
              ]}
              label={"Management"}
              path="/management"
            />
          </div>
        </div>
        <SidebarComponent
          icon={<TbContract />}
          label={"Contracts"}
          path="/contracts"
        />
        <div className="mb-10 space-y-1">
          <SidebarComponent
            icon={<IoSettingsOutline />}
            label={"Settings"}
            path="/settings"
          />

          <SidebarComponent
            icon={<TbLogs />}
            label={"Users Logs"}
            path="/logs"
          />
        </div>
      </div>
      {/* <button className="bg-[#3B3C65]  text-base font-semibold text-white rounded-xl py-[12px] flex justify-center items-center space-x-3  mb-8">
        <div className="text-xl">
          <FiBookOpen />
        </div>
        <div>Terms of use</div>
      </button> */}
    </div>
  );
};

export default AdminSidebar;
