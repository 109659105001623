import { useState } from "react";
import TextForm from "../textForm";
import SubmitButton from "../submitButton";
import { editInfluencerDetail } from "../../controllers/influencerDetailController";
import toast from "react-hot-toast";

const SocialMediaStatsForm = ({ user, getData }) => {
  const [stats, setStats] = useState({
    instagramStats: {
      CPE: user.InfluencerDetail?.instagramStats.CPE || 0,
      engagement: user.InfluencerDetail?.instagramStats.engagement || 0,
      views: user.InfluencerDetail?.instagramStats.views || 0,
      comments: user.InfluencerDetail?.instagramStats.comments || 0,
      likes: user.InfluencerDetail?.instagramStats.likes || 0,
    },
    tiktokStats: {
      CPE: user.InfluencerDetail?.tiktokStats.CPE || 0,
      engagement: user.InfluencerDetail?.tiktokStats.engagement || 0,
      views: user.InfluencerDetail?.tiktokStats.views || 0,
      comments: user.InfluencerDetail?.tiktokStats.comments || 0,
      likes: user.InfluencerDetail?.tiktokStats.likes || 0,
    },
    xStats: {
      CPE: user.InfluencerDetail?.xStats.CPE || 0,
      engagement: user.InfluencerDetail?.xStats.engagement || 0,
      views: user.InfluencerDetail?.xStats.views || 0,
      comments: user.InfluencerDetail?.xStats.comments || 0,
      likes: user.InfluencerDetail?.xStats.likes || 0,
    },
    youtubeStats: {
      CPE: user.InfluencerDetail?.youtubeStats.CPE || 0,
      engagement: user.InfluencerDetail?.youtubeStats.engagement || 0,
      views: user.InfluencerDetail?.youtubeStats.views || 0,
      comments: user.InfluencerDetail?.youtubeStats.comments || 0,
      likes: user.InfluencerDetail?.youtubeStats.likes || 0,
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const [platform, field] = name.split(".");
    setStats((prev) => ({
      ...prev,
      [platform]: {
        ...prev[platform],
        [field]: value,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitted Stats:", stats);
    editInfluencerDetail(user.InfluencerDetail.uuid, stats).then((res) => {
      getData();
      toast.success("Saved successfully");
    });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full bg-white p-8 rounded-lg mt-4"
    >
      <h1 className="font-bold text-lg">Social Media Stats</h1>
      <p className="text-sm text-muted mb-4">
        Edit Social Media stats for all platforms below
      </p>
      <div className="grid grid-cols-3 gap-5">
        {/* Instagram Stats */}
        <div>
          <h2 className="font-bold text-md mb-2">Instagram</h2>
          {["CPE", "engagement", "views", "comments", "likes"].map((field) => (
            <TextForm
              required={false}
              key={`instagramStats.${field}`}
              label={`Instagram ${field}`}
              placeholder={`Enter Instagram ${field}`}
              name={`instagramStats.${field}`}
              inputType={"number"}
              value={stats.instagramStats[field]}
              onChange={handleInputChange}
            />
          ))}
        </div>
        {/* TikTok Stats */}
        <div>
          <h2 className="font-bold text-md mb-2">TikTok</h2>
          {["CPE", "engagement", "views", "comments", "likes"].map((field) => (
            <TextForm
              required={false}
              key={`tiktokStats.${field}`}
              label={`TikTok ${field}`}
              placeholder={`Enter TikTok ${field}`}
              name={`tiktokStats.${field}`}
              inputType={"number"}
              value={stats.tiktokStats[field]}
              onChange={handleInputChange}
            />
          ))}
        </div>
        {/* X Stats */}
        <div>
          <h2 className="font-bold text-md mb-2">X</h2>
          {["CPE", "engagement", "views", "comments", "likes"].map((field) => (
            <TextForm
              required={false}
              key={`xStats.${field}`}
              label={`X ${field}`}
              placeholder={`Enter X ${field}`}
              name={`xStats.${field}`}
              inputType={"number"}
              value={stats.xStats[field]}
              onChange={handleInputChange}
            />
          ))}
        </div>
        {/* YouTube Stats */}
        <div>
          <h2 className="font-bold text-md mb-2">YouTube</h2>
          {["CPV", "engagement", "views", "comments", "likes"].map((field) => (
            <TextForm
              required={false}
              key={`youtubeStats.${field}`}
              label={`YouTube ${field}`}
              placeholder={`Enter YouTube ${field}`}
              name={`youtubeStats.${field}`}
              inputType={"number"}
              value={stats.youtubeStats[field]}
              onChange={handleInputChange}
            />
          ))}
        </div>
      </div>
      <SubmitButton text={"Save changes"} />
    </form>
  );
};

export default SocialMediaStatsForm;
