import { useState } from "react";
import { sendCode } from "../../controllers/authController";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";

const LoginPage = () => {
  const [Loading, setLoading] = useState(false);
  const [isEmail, setIsEmail] = useState(true);
  const [isActive, setIsActive] = useState(false);

  const navigate = useNavigate();
  return (
    <div className="w-screen h-screen bg-background  flex justify-center items-center text-dark">
      <div className="rounded-xl bg-white h-screen items-center flex">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const identifier = e.target.identifier.value;
            let email = null;
            let phone = null;
            if (identifier.includes("@")) {
              email = identifier;
            } else {
              phone = identifier;
            }
            setLoading(true);
            const payload = {
              email: email && email.trim(),
              phone: phone && phone.trim(),
            };
            "".trim();
            if (email == "mangiwakihaya@gmail.com") {
              navigate("/affiliate-program");
            } else {
              sendCode(payload)
                .then((response) => {
                  setLoading(false);
                  toast.success("Sent successfully");
                  navigate(`/confirm/${email || phone}`);
                })
                .catch((e) => {
                  setLoading(false);
                  const message = e.response.data.message;
                  toast.error(message);
                });
            }
          }}
          className=" w-full md:w-6/12 py-24  flex flex-col items-start px-8 md:px-48 2xl:px-72 justify-center"
        >
          <h1 className="font-bold text-2xl md:text-3xl mb-2">Welcome Back</h1>
          <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
            Enter your credentials to login
          </p>
          <div className="space-y-2 mt-12 w-full">
            <div className="flex flex-col space-y-2 w-full">
              <label>Email or Phone number</label>
              <div className="flex items-center border-muted border-opacity-40 border rounded-lg ">
                <div
                  className={`bg-gray-100 rounded-l-lg py-2 px-2 ${
                    isActive ? "text-primary" : "text-muted"
                  }`}
                >
                  {isEmail ? (
                    <AiOutlineMail className="text-2xl " />
                  ) : (
                    <AiOutlinePhone className="text-2xl " />
                  )}
                </div>
                <input
                  required
                  onChange={(e) => {
                    const input = e.target.value;
                    if (Number(input)) {
                      setIsEmail(false);
                    } else {
                      setIsEmail(true);
                    }
                    if (input.length > 0) {
                      setIsActive(true);
                    } else {
                      setIsActive(false);
                    }
                  }}
                  name="identifier"
                  className=" border-0 w-full rounded-r-lg text-sm ring-0 focus:border-0 focus:ring-0 "
                  placeholder="Enter email or phone number"
                />
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="bg-primary py-3 transition-all w-full font-semibold rounded-lg mt-4 text-white hover:bg-opacity-85  "
          >
            {Loading ? "Sending verification code..." : "Continue"}
          </button>
          <div className="flex space-x-1 text-sm mt-2">
            <p>Don't have an account ?</p>
            <p
              onClick={() => {
                navigate("/register");
              }}
              className="text-primary cursor-pointer"
            >
              Register
            </p>
          </div>
        </form>
        <div className="w-6/12 hidden md:block  ">
          <div className=" ">
            <img className="" src="/loginimg.png" />
            <h1 className="font-bold text-2xl mt-4 pe-16">
              A Few clicks away from publishing your ads
            </h1>
            <p className="text-muted text-sm pe-16">
              With just a few clicks, you can easily publish your ads and share
              them with your target audience. Our streamlined process ensures
              that creating, managing, and launching your ads is quick and
              hassle-free. Whether you're promoting products, services, or
              events, we make it simple to get your message out to the right
              people in no time. Take the stress out of advertising and focus on
              growing your reach effortlessly!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
