import { useState } from "react";
import TextForm from "../textForm";
import SubmitButton from "../submitButton";
import { editInfluencerDetail } from "../../controllers/influencerDetailController";
import toast from "react-hot-toast";

const SocialMediaPriceForm = ({ user, getData }) => {
  const [formValues, setFormValues] = useState({
    instagramPriceRange: {
      min: user.InfluencerDetail?.instagramPriceRange.min || 0,
      max: user.InfluencerDetail?.instagramPriceRange.max || 0,
    },
    tiktokPriceRange: {
      min: user.InfluencerDetail?.tiktokPriceRange.min || 0,
      max: user.InfluencerDetail?.tiktokPriceRange.max || 0,
    },
    xPriceRange: {
      min: user.InfluencerDetail?.xPriceRange.min || 0,
      max: user.InfluencerDetail?.xPriceRange.max || 0,
    },
    youtubePriceRange: {
      min: user.InfluencerDetail?.youtubePriceRange.min || 0,
      max: user.InfluencerDetail?.youtubePriceRange.max || 0,
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const [platform, field] = name.split(".");
    setFormValues((prev) => ({
      ...prev,
      [platform]: {
        ...prev[platform],
        [field]: value,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitted Price Ranges:", formValues);
    editInfluencerDetail(user.InfluencerDetail.uuid, formValues).then((res) => {
      getData();
      toast.success("Saved successfully");
    });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full bg-white p-8 rounded-lg mt-4"
    >
      <h1 className="font-bold text-lg">Social Media Price Ranges</h1>
      <p className="text-sm text-muted mb-4">
        Edit minimum and maximum price ranges below
      </p>
      <div className="grid grid-cols-3 gap-5">
        <div className="space-y-1">
          <h1 className="font-bold ">Instagram</h1>
          <TextForm
            required={false}
            label={"Instagram Minimum Price"}
            placeholder={"Enter minimum price for Instagram"}
            name={"instagramPriceRange.min"}
            inputType={"number"}
            value={formValues.instagramPriceRange.min}
            onChange={handleInputChange}
          />
          <TextForm
            required={false}
            label={"Instagram Maximum Price"}
            placeholder={"Enter maximum price for Instagram"}
            name={"instagramPriceRange.max"}
            inputType={"number"}
            value={formValues.instagramPriceRange.max}
            onChange={handleInputChange}
          />
        </div>
        <div className="space-y-1">
          <h1 className="font-bold ">Tiktok</h1>
          <TextForm
            required={false}
            label={"TikTok Minimum Price"}
            placeholder={"Enter minimum price for TikTok"}
            name={"tiktokPriceRange.min"}
            inputType={"number"}
            value={formValues.tiktokPriceRange.min}
            onChange={handleInputChange}
          />
          <TextForm
            required={false}
            label={"TikTok Maximum Price"}
            placeholder={"Enter maximum price for TikTok"}
            name={"tiktokPriceRange.max"}
            inputType={"number"}
            value={formValues.tiktokPriceRange.max}
            onChange={handleInputChange}
          />
        </div>
        <div className="space-y-1">
          <h1 className="font-bold ">X</h1>
          <TextForm
            required={false}
            label={"X Minimum Price"}
            placeholder={"Enter minimum price for X"}
            name={"xPriceRange.min"}
            inputType={"number"}
            value={formValues.xPriceRange.min}
            onChange={handleInputChange}
          />
          <TextForm
            required={false}
            label={"X Maximum Price"}
            placeholder={"Enter maximum price for X"}
            name={"xPriceRange.max"}
            inputType={"number"}
            value={formValues.xPriceRange.max}
            onChange={handleInputChange}
          />
        </div>
        <div className="space-y-1">
          <h1 className="font-bold ">Youtube</h1>
          <TextForm
            required={false}
            label={"YouTube Minimum Price"}
            placeholder={"Enter minimum price for YouTube"}
            name={"youtubePriceRange.min"}
            inputType={"number"}
            value={formValues.youtubePriceRange.min}
            onChange={handleInputChange}
          />
          <TextForm
            required={false}
            label={"YouTube Maximum Price"}
            placeholder={"Enter maximum price for YouTube"}
            name={"youtubePriceRange.max"}
            inputType={"number"}
            value={formValues.youtubePriceRange.max}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <SubmitButton text={"Save changes"} />
    </form>
  );
};

export default SocialMediaPriceForm;
