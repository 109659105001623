import { IoDocumentAttachOutline } from "react-icons/io5";
import SidebarItem from "../sidebarItem";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { UserContext } from "../../layouts/dashboardLayout";
import { useContext } from "react";
import SidebarComponent from "../sidebarComponent";

const SidebarLegalSection = () => {
  const { user, setShowPrivacyModal, setShowTermsModal } =
    useContext(UserContext);
  return (
    <div className="pt-5">
      <h1 className="font-bold text-xs text-white text-opacity-60 mb-2">
        LEGAL DOCUMENTS
      </h1>
      <SidebarComponent
        icon={<MdOutlinePrivacyTip />}
        label={"Privacy Policy"}
        onClick={() => {
          setShowPrivacyModal(true);
        }}
        path="/privacy-policy"
      />
      <SidebarComponent
        icon={<IoDocumentAttachOutline />}
        label={"Terms of use"}
        onClick={() => {
          setShowTermsModal(true);
        }}
        path="/terms-of-use"
      />
    </div>
  );
};

export default SidebarLegalSection;
