import { useEffect, useRef, useState } from "react";
import {
  deleteUser,
  getInfluencers,
  getUsers,
} from "../../controllers/userController";
import Spinner from "../../components/spinner";
import Loader from "../../components/loader";
import Pagination from "../../components/pagination";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import SidebarItem from "../../components/sidebarItem";
import {
  AiFillInstagram,
  AiFillTikTok,
  AiFillTwitterSquare,
  AiFillX,
  AiFillYoutube,
  AiOutlineCopy,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineUser,
} from "react-icons/ai";
import toast from "react-hot-toast";
import { closePopupMenu } from "../../utils/closePopupMenu";
import { HiDotsVertical } from "react-icons/hi";
import Back from "../../components/back";
import {
  getInfluencerGroup,
  getInfluencerGroups,
} from "../../controllers/influencerGroupsController";
import ModalBottom from "../../components/modalBottom";
import ProgressBar from "../../components/progressBar";
import Switch from "../../components/switch";
import { editInfluencerGroupMembers } from "../../controllers/influencerGroupMembersController";
import { FaSquareXTwitter } from "react-icons/fa6";
import { formatNumber } from "../../utils/numberFormater";

const SharedInfluencersGroup = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const { uuid } = useParams();

  useEffect(() => {
    getData();
  }, [page, keyword]);
  const getData = () => {
    getInfluencerGroup(uuid).then((response) => {
      const data = response.data.body;
      console.log(data);
      setData(data);
      setLoading(false);
    });
  };
  return loading ? (
    <Loader />
  ) : (
    <div className=" bg-background min-h-screen mx-auto py-4">
      <div className="w-11/12 2xl:w-8/12 mx-auto">
        <div className="flex space-x-4">
          <div className="">
            <h1 className="text-2xl font-bold ">Kwanza Influencers</h1>
            <p className="text-sm text-muted">
              Below is the List of shared influencer select influencers you want
            </p>
          </div>
        </div>
        <div className="bg-white mt-4  p-6 mx-auto rounded-lg ">
          <table>
            <thead>
              <tr>
                <th>Activate</th>

                <th>Profile Name</th>
                <th>Status</th>
                <th>Owners</th>
                <th>Followers X</th>
                <th>Followers Youtube</th>
                <th>Followers Instagram</th>
                <th>Followers Tiktok</th>
                <th>Influencer Tag</th>
                <th>Country</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.InfluencerGroupMembers.map((item) => {
                return (
                  <tr>
                    <td>
                      <Switch
                        isActive={item.isSelected}
                        onClick={() => {
                          toast.success(
                            item.isSelected
                              ? "Influencer Unselected"
                              : "Influencer Selected"
                          );
                          editInfluencerGroupMembers(item.uuid, {
                            isSelected: !item.isSelected,
                          }).then((res) => {
                            getData();
                          });
                        }}
                      />
                    </td>
                    <td>{item.User.name}</td>
                    <td>
                      {item.isSelected
                        ? "Contracted"
                        : item.User.InfluencerDetail.status}
                    </td>
                    <td>Kwanza</td>
                    <td>
                      {formatNumber(item.User.InfluencerDetail.followersX)}
                    </td>
                    <td>
                      {formatNumber(
                        item.User.InfluencerDetail.followersYoutube
                      )}
                    </td>
                    <td>
                      {formatNumber(
                        item.User.InfluencerDetail.followersInstagram
                      )}
                    </td>
                    <td>
                      {formatNumber(item.User.InfluencerDetail.followersTiktok)}
                    </td>
                    <td>{item.User.InfluencerDetail.tag}</td>
                    <td>{item.User.InfluencerDetail.country}</td>
                    <td>
                      <button
                        onClick={() => {
                          setSelectedItem(item.User);
                          setShowModal(true);
                        }}
                        className="bg-primary text-nowrap text-xs p-2 text-white rounded-lg font-semibold"
                      >
                        View Stats
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <ModalBottom
            showModal={showModal}
            setShowModal={() => {
              setShowModal(false);
            }}
            content={
              selectedItem != null && (
                <div>
                  <div className="">
                    <div className="flex items-center space-x-4">
                      {/* <AiOutlineUser className="size-20 text-muted" /> */}
                      <img
                        className="size-24 rounded-lg"
                        src={selectedItem.InfluencerDetail.profileUrl}
                      />
                      <div>
                        <h1 className="font-bold">{selectedItem.name}</h1>
                        {/* <h1 className="text-muted text-sm">
                        Email:{selectedItem.email}
                      </h1>
                      <h1 className="text-muted text-sm">
                        Phone:{selectedItem.phone}
                      </h1> */}
                        <h1 className="text-muted text-sm">
                          Tag: {selectedItem.InfluencerDetail.tag}
                        </h1>
                        <h1 className="text-muted text-sm">
                          Country: {selectedItem.InfluencerDetail.country}
                        </h1>
                      </div>
                    </div>
                  </div>
                  {/* second section */}
                  <div className="flex flex-col 2xl:flex-row space-y-4 2xl:space-y-0 2xl:space-x-4 items-stretch mt-4">
                    <div className="w-full 2xl:w-8/12 border rounded-lg p-6  ">
                      <p className="font-bold text-lg ">Audience Stats </p>

                      <div className=" ">
                        <div className="grid grid-cols-5 items-center   ">
                          <div className=" items-center flex flex-col">
                            <h1 className="text-2xl font-bold text-primary">
                              {selectedItem.InfluencerDetail.real}%
                            </h1>
                            <p className="text-muted">Real</p>
                          </div>
                          <div className=" flex flex-col items-center ">
                            <div>
                              {Object.keys(
                                selectedItem.InfluencerDetail.audienceByCity
                              ).map((item) => {
                                return (
                                  <div className="flex space-x-2 items-center ">
                                    <p className="w-5/12 ">{item} </p>
                                    <div className="">
                                      <ProgressBar
                                        value={
                                          selectedItem.InfluencerDetail
                                            .audienceByCity[item]
                                        }
                                      />
                                    </div>
                                    <p className="w-1/12">
                                      {
                                        selectedItem.InfluencerDetail
                                          .audienceByCity[item]
                                      }
                                      %
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                            <p className="flex items-center space-x-1 ">
                              <div className="size-2 rounded-full bg-yellow-500"></div>{" "}
                              <p className="text-muted"> Cities</p>
                            </p>
                          </div>
                          <div className="flex flex-col items-center ">
                            <div>
                              {Object.keys(
                                selectedItem.InfluencerDetail.audienceByCountry
                              ).map((item) => {
                                return (
                                  <div className="flex space-x-2 items-center">
                                    <p className="w-3/12">{item} </p>
                                    <div className="grow">
                                      <ProgressBar
                                        value={
                                          selectedItem.InfluencerDetail
                                            .audienceByCountry[item]
                                        }
                                      />
                                    </div>
                                    <p className="w-1/12">
                                      {
                                        selectedItem.InfluencerDetail
                                          .audienceByCountry[item]
                                      }
                                      %
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                            <p className="flex items-center space-x-1 ">
                              <div className="size-2 rounded-full bg-yellow-500"></div>{" "}
                              <p className="text-muted"> Country</p>
                            </p>
                          </div>
                          <div className="flex flex-col items-center ">
                            <div>
                              {Object.keys(
                                selectedItem.InfluencerDetail.audienceByAge
                              ).map((item) => {
                                return (
                                  <div className="flex space-x-2 items-center">
                                    <p className="w-3/12">{item} </p>
                                    <div className="">
                                      <ProgressBar
                                        value={
                                          selectedItem.InfluencerDetail
                                            .audienceByAge[item]
                                        }
                                      />
                                    </div>
                                    <p className="w-1/12">
                                      {
                                        selectedItem.InfluencerDetail
                                          .audienceByAge[item]
                                      }
                                      %
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                            <p className="flex items-center space-x-1 ">
                              <div className="size-2 rounded-full bg-yellow-500"></div>{" "}
                              <p className="text-muted"> Age</p>
                            </p>
                          </div>
                          <div className=" items-center flex flex-col">
                            <h1 className="text-2xl font-bold text-primary">
                              {
                                Object.values(
                                  selectedItem.InfluencerDetail
                                    .audiencesByGender
                                )[0]
                              }
                              %
                            </h1>
                            <p className="text-muted">
                              {
                                Object.keys(
                                  selectedItem.InfluencerDetail
                                    .audiencesByGender
                                )[0]
                              }
                            </p>
                            <div className="mt-3">
                              <h1 className="text-2xl font-bold text-primary">
                                {
                                  Object.values(
                                    selectedItem.InfluencerDetail
                                      .audiencesByGender
                                  )[1]
                                }
                                %
                              </h1>
                              <p className="text-muted">
                                {
                                  Object.keys(
                                    selectedItem.InfluencerDetail
                                      .audiencesByGender
                                  )[1]
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="2xl:w-4/12 ">
                      <div className=" border p-6 rounded-lg">
                        <p className="font-bold text-lg ">Brand mentions </p>
                        <div className="flex space-x-8 mt-2">
                          <div>
                            <h1 className="text-primary font-bold text-2xl">
                              {selectedItem.InfluencerDetail.saturationRate}%
                            </h1>
                            <p className="text-sm text-muted">
                              Saturation rate
                            </p>
                          </div>
                          <div>
                            <h1 className="text-primary font-bold text-2xl">
                              {selectedItem.InfluencerDetail.postEffectiveness}%
                            </h1>
                            <p className="text-sm text-muted">
                              Post effectiveness
                            </p>
                          </div>
                        </div>
                        <p className="text-sm font-bold mt-8">
                          Top mentioned brands{" "}
                        </p>
                        {selectedItem.InfluencerBrandMentions.map((item) => {
                          return (
                            <div className="flex">
                              <div className="flex items-center space-x-6  p-2 rounded-lg shadow w-full">
                                <img
                                  className=" h-8 rounded"
                                  src={item.brandLogo}
                                />
                                <h1>{item.brandName}</h1>
                                <div>mentions: {item.mentions}</div>
                                <div>Effectiveness: {item.effectiveness}%</div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  {/* last section  */}
                  {/* instagram */}
                  <div className="flex mt-4 bg-pink-100 space-x-8 items-center">
                    <div className="size-24 bg-pink-600 justify-center items-center flex flex-col">
                      <AiFillInstagram className="text-white text-xl" />
                      <p className="text-sm text-white font-medium">
                        {formatNumber(
                          selectedItem.InfluencerDetail.followersInstagram
                        )}
                      </p>
                    </div>
                    <div>
                      <h1 className=" font-bold text-xl">
                        <a
                          target="_black"
                          href={selectedItem.InfluencerDetail.instagramLink}
                        >
                          <p>{selectedItem.InfluencerDetail.instagramHandle}</p>
                        </a>
                      </h1>
                      <p className="text-sm text-dark">Instagram</p>
                    </div>
                    <div>
                      <h1 className=" font-bold text-xl">
                        <p>
                          $
                          {parseInt(
                            selectedItem.InfluencerDetail.instagramPriceRange
                              .min
                          ).toLocaleString()}
                          - $
                          {parseInt(
                            selectedItem.InfluencerDetail.instagramPriceRange
                              .max
                          ).toLocaleString()}
                        </p>
                      </h1>
                      <p className="text-sm text-dark">Price range</p>
                    </div>

                    <div>
                      <h1 className=" font-bold text-xl">
                        <p>
                          {
                            selectedItem.InfluencerDetail.instagramStats
                              .engagement
                          }
                          %
                        </p>
                      </h1>
                      <p className="text-sm text-dark">
                        Avarage Engagement rate
                      </p>
                    </div>
                    <div>
                      <h1 className=" font-bold text-xl">
                        <p>
                          {" "}
                          ${selectedItem.InfluencerDetail.instagramStats.CPE}
                        </p>
                      </h1>
                      <p className="text-sm text-dark">CPE</p>
                    </div>
                    <div>
                      <h1 className=" font-bold text-xl">
                        <p>
                          {" "}
                          {parseInt(
                            selectedItem.InfluencerDetail.instagramStats.likes
                          ).toLocaleString()}
                        </p>
                      </h1>
                      <p className="text-sm text-dark">Avarage Likes</p>
                    </div>
                    <div>
                      <h1 className=" font-bold text-xl">
                        <p>
                          {" "}
                          {parseInt(
                            selectedItem.InfluencerDetail.instagramStats
                              .comments
                          ).toLocaleString()}
                        </p>
                      </h1>
                      <p className="text-sm text-dark">Avarage comments</p>
                    </div>
                  </div>
                  {/* Youtube */}
                  <div className="flex mt-4 bg-red-100 space-x-8 items-center">
                    <div className="size-24 bg-red-600 justify-center items-center flex flex-col">
                      <AiFillYoutube className="text-white text-xl" />
                      <p className="text-sm text-white font-medium">
                        {formatNumber(
                          selectedItem.InfluencerDetail.followersYoutube
                        )}
                      </p>
                    </div>
                    <div>
                      <h1 className=" font-bold text-xl">
                        <a
                          target="_black"
                          href={selectedItem.InfluencerDetail.youtubeLink}
                        >
                          <p>{selectedItem.InfluencerDetail.youtubeHandle}</p>
                        </a>
                      </h1>
                      <p className="text-sm text-dark">Youtube</p>
                    </div>
                    <div>
                      <h1 className=" font-bold text-xl">
                        <p>
                          $
                          {parseInt(
                            selectedItem.InfluencerDetail.youtubePriceRange.min
                          ).toLocaleString()}{" "}
                          - $
                          {parseInt(
                            selectedItem.InfluencerDetail.youtubePriceRange.max
                          ).toLocaleString()}
                        </p>
                      </h1>
                      <p className="text-sm text-dark">Price range</p>
                    </div>

                    <div>
                      <h1 className=" font-bold text-xl">
                        <p>
                          {
                            selectedItem.InfluencerDetail.youtubeStats
                              .engagement
                          }
                          %
                        </p>
                      </h1>
                      <p className="text-sm text-dark">
                        Avarage Engagement rate
                      </p>
                    </div>
                    <div>
                      <h1 className=" font-bold text-xl">
                        <p>
                          {" "}
                          $ {selectedItem.InfluencerDetail.youtubeStats.CPE}
                        </p>
                      </h1>
                      <p className="text-sm text-dark">CPE</p>
                    </div>
                    <div>
                      <h1 className=" font-bold text-xl">
                        <p>
                          {" "}
                          {parseInt(
                            selectedItem.InfluencerDetail.youtubeStats.likes
                          ).toLocaleString()}
                        </p>
                      </h1>
                      <p className="text-sm text-dark">Avarage Likes</p>
                    </div>
                    <div>
                      <h1 className=" font-bold text-xl">
                        <p>
                          {" "}
                          {parseInt(
                            selectedItem.InfluencerDetail.youtubeStats.comments
                          ).toLocaleString()}
                        </p>
                      </h1>
                      <p className="text-sm text-dark">Avarage comments</p>
                    </div>
                  </div>
                  {/* x */}
                  <div className="flex mt-4 bg-black bg-opacity-10 space-x-8 items-center">
                    <div className="size-24 bg-black justify-center items-center flex flex-col">
                      <FaSquareXTwitter className="text-white text-xl" />
                      <p className="text-sm text-white font-medium">
                        {formatNumber(selectedItem.InfluencerDetail.followersX)}
                      </p>
                    </div>
                    <div>
                      <h1 className=" font-bold text-xl">
                        <a
                          target="_black"
                          href={selectedItem.InfluencerDetail.xLink}
                        >
                          <p>{selectedItem.InfluencerDetail.xHandle}</p>
                        </a>
                      </h1>
                      <p className="text-sm text-dark">X</p>
                    </div>
                    <div>
                      <h1 className=" font-bold text-xl">
                        <p>
                          $
                          {parseInt(
                            selectedItem.InfluencerDetail.xPriceRange.min
                          ).toLocaleString()}{" "}
                          - $
                          {parseInt(
                            selectedItem.InfluencerDetail.xPriceRange.max
                          ).toLocaleString()}
                        </p>
                      </h1>
                      <p className="text-sm text-dark">Price range</p>
                    </div>

                    <div>
                      <h1 className=" font-bold text-xl">
                        <p>
                          {selectedItem.InfluencerDetail.xStats.engagement}%
                        </p>
                      </h1>
                      <p className="text-sm text-dark">
                        Avarage Engagement rate
                      </p>
                    </div>
                    <div>
                      <h1 className=" font-bold text-xl">
                        <p> ${selectedItem.InfluencerDetail.xStats.CPE}</p>
                      </h1>
                      <p className="text-sm text-dark">CPE</p>
                    </div>
                    <div>
                      <h1 className=" font-bold text-xl">
                        <p>
                          {" "}
                          {parseInt(
                            selectedItem.InfluencerDetail.xStats.likes
                          ).toLocaleString()}
                        </p>
                      </h1>
                      <p className="text-sm text-dark">Avarage Likes</p>
                    </div>
                    <div>
                      <h1 className=" font-bold text-xl">
                        <p>
                          {" "}
                          {parseInt(
                            selectedItem.InfluencerDetail.xStats.comments
                          ).toLocaleString()}
                        </p>
                      </h1>
                      <p className="text-sm text-dark">Avarage comments</p>
                    </div>
                  </div>
                  {/* tiktok */}
                  <div className="flex mt-4 bg-dark bg-opacity-10 space-x-8 items-center">
                    <div className="size-24 bg-dark justify-center items-center flex flex-col">
                      <AiFillTikTok className="text-white text-xl" />
                      <p className="text-sm text-white font-medium">
                        {formatNumber(
                          selectedItem.InfluencerDetail.followersTiktok
                        )}
                      </p>
                    </div>
                    <div>
                      <h1 className=" font-bold text-xl">
                        <a
                          target="_black"
                          href={selectedItem.InfluencerDetail.tiktokLink}
                        >
                          <p>{selectedItem.InfluencerDetail.tiktokHandle}</p>
                        </a>
                      </h1>
                      <p className="text-sm text-dark">Tiktok</p>
                    </div>
                    <div>
                      <h1 className=" font-bold text-xl">
                        <p>
                          $
                          {parseInt(
                            selectedItem.InfluencerDetail.tiktokPriceRange.min
                          ).toLocaleString()}{" "}
                          - $
                          {parseInt(
                            selectedItem.InfluencerDetail.tiktokPriceRange.max
                          ).toLocaleString()}
                        </p>
                      </h1>
                      <p className="text-sm text-dark">Price range</p>
                    </div>

                    <div>
                      <h1 className=" font-bold text-xl">
                        <p>
                          {selectedItem.InfluencerDetail.tiktokStats.engagement}
                          %
                        </p>
                      </h1>
                      <p className="text-sm text-dark">
                        Avarage Engagement rate
                      </p>
                    </div>
                    <div>
                      <h1 className=" font-bold text-xl">
                        <p> ${selectedItem.InfluencerDetail.tiktokStats.CPE}</p>
                      </h1>
                      <p className="text-sm text-dark">CPE</p>
                    </div>
                    <div>
                      <h1 className=" font-bold text-xl">
                        <p>
                          {" "}
                          {parseInt(
                            selectedItem.InfluencerDetail.tiktokStats.likes
                          ).toLocaleString()}
                        </p>
                      </h1>
                      <p className="text-sm text-dark">Avarage Likes</p>
                    </div>
                    <div>
                      <h1 className=" font-bold text-xl">
                        <p>
                          {" "}
                          {parseInt(
                            selectedItem.InfluencerDetail.tiktokStats.comments
                          ).toLocaleString()}
                        </p>
                      </h1>
                      <p className="text-sm text-dark">Avarage comments</p>
                    </div>
                  </div>
                </div>
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default SharedInfluencersGroup;
