import { useEffect, useState } from "react";
import Back from "../../components/back";
import TextForm from "../../components/textForm";
import AudienceForm from "../../components/influncer forms/audienceForm";
import SocialMediaStatsForm from "../../components/influncer forms/statsForm";
import SocialMediaPriceForm from "../../components/influncer forms/priceRangeForm";
import BrandMentionsForm from "../../components/influncer forms/brandMentionsForm";
import PrimaryDetailsForm from "../../components/influncer forms/primaryDetailsForm";
import { addInfluencerDetail } from "../../controllers/influencerDetailController";
import { getUser } from "../../controllers/userController";
import { useParams } from "react-router-dom";
import Loader from "../../components/loader";

const InfluencerDetails = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const { uuid } = useParams();

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    getUser(uuid).then((ref) => {
      console.log(ref.data);
      setUser(ref.data.body);
      if (!ref.data.body.InfluencerDetail) {
        console.log("Creating");
        addInfluencerDetail({ user_uuid: uuid }).then(() => {
          console.log("Created");
          getData();
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    });
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Back />
      <div className=" mb-4">
        <h1 className="text-2xl 2xl:text-xl font-bold">{user.name} Details</h1>
        <p className="text-base text-muted dark:text-white dark:text-opacity-50">
          Edit {user.name} details below
        </p>
      </div>
      <PrimaryDetailsForm user={user} getData={getData} />
      <SocialMediaPriceForm user={user} getData={getData} />
      <SocialMediaStatsForm user={user} getData={getData} />
      <AudienceForm user={user} getData={getData} />
      <BrandMentionsForm user={user} getData={getData} />
    </div>
  );
};

export default InfluencerDetails;
